@import 'variables.scss';

.dropZoneStyle {
  border: 2px dashed !important;
  border-color: lightgrey !important;
  border-radius: 5px;
  text-align: center;
  color: grey;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

    padding: 5px !important;
    cursor: pointer;
    min-height: 100px;
}

.favicon-description {
  margin: 20px 0;
}

.favicon-title {
  margin-bottom: 10px;
}

.favicon-preview {
  margin: 30px 0 10px 0;
}

.favicon-container {
  display: flex;
  justify-content: flex-start;

  .preview-favicon {
    width: 100px;
    height: 100px;
    border-radius: 5px;
  }
}

.favicon-cta {
  display: flex;
  color: #878c9b;
  margin-top: 10px;

  .favicon-change {
    cursor: pointer;
  }

  .favicon-remove {
    cursor: pointer;
  }
}

.preview-container {
  width: 100%;

  .preview-top {
    height: 40px;
    width: 100%;
    display: flex;
    background-color: #e8e8e8;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    .circles {
      width: 10%;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      margin-left: 10px;
      margin-top: 3px;

      .circle {
        width: 12px;
        height: 12px;
        background-color: #ffffff;
        border-radius: 500px;
      }
    }

    .tab {
      width: 30%;
      height: 30px;
      background-color: #f3f3f3;
      margin-top: 10px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin-left: 5px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;

      .img {
        background-color: #ffffff;
        width: 20px;
        height: 20px;
        border: solid 1px #979797;
      }

      .title {
        height: 10px;
        width: 60%;
        background-color: #e8e8e8;
      }
    }
  }

  .preview-bottom {
    height: 40px;
    width: 100%;
    background-color: #f3f3f3;
    display: flex;

    .squares {
      width: 15%;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: space-around;

      .square {
        width: 18px;
        height: 18px;
        background-color: #e8e8e8;
        border-radius: 5px;
      }
    }

    .url-container {
      height: 30px;
      width: 80%;
      background-color: #ffffff;
      border-radius: 5px;
      margin-top: 5px;
      display: flex;
      align-items: center;
      margin-left: 5px;

      .url {
        padding-left: 5px;
        color: #696969;
        font-family: Arial;
        font-size: 16px;
        cursor: auto;
      }
    }
  }
}

.add-favicon-container, .add-social-container {
  .add-favicon-image {
    border-radius: 5px;
    height: 75px;
    width: 75px;
  }

  .add-social-image {
    border-radius: 5px;
    width: 100%;
  }

  .wz-button {
    width: 100%;
  }
}

.dropZoneStyle:hover {
  border-color: #0033B2 !important;
  color: #0033B2;
}

.dropzone-title {
  margin-bottom: 5px;
}

.dropzone-button-container {
  display: flex;
}

.dropZone-label {
  margin-top: 10px;
  font-size: 10px;
}

.dropzone-error-over-limit {
  color: red;
  margin-top: 10px;
}

.dropZoneStyle:focus {
  outline-style: none;
}

.dropZoneStyleRejected {
  border-color: red !important;
  color: red;
}

.dropZoneStyleValidate {
  border: none;
  background-color: #C1CEEC;
  color: rgb(var(--primary-color));
}

.typeFile {
  font-size: 10px;
  padding: 20px;
}

.textInfo {
  font-size: 10px;
  margin-bottom: 5px;
}

