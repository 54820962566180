.wz-c-price-row-modal-filter {
  .delete-icon {
    position: relative;
    top: 39px;
  }

  .select {
    flex-grow: 1;
  }
}
