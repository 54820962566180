@import "../variables";
@import "app/styles/variables";

.button {
  padding: 10px;
  display: flex;
  align-content: center;
  align-items: center;
  background-color: $brand-primary;
  border-radius: 0 6px 6px 0;
  transition: background-color $transition-delay;
  outline-width: 2px;
  outline-color: black;

  &:hover {
    background-color: $background-hover;
  }
}

.buttonIcon {
  position: relative;
  top: 2px;
}
