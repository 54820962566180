.wz-daphne__bank-bag-cash-details-grid {
  &__grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 16px;
  }

  &__buttons {
    display: flex;
    gap: 12px;
  }
}
