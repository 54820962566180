@import "../../../../../../../../styles/mixins";

.bold {
  font-weight: 500;
}

.separator {
  height: 16px;
}

.fields {
  display: flex;
  gap: 10px;
  align-items: flex-end;
  flex-wrap: wrap;
  @include tablet {
    & > * {
      flex-grow: 1;
    }
  }
}

.root {
  @include tablet {
    :global(.wz-currency-input) {
      max-width: initial !important
    }
  }
}
