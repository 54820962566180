.wz-c-accordion-action-button {
  background-color: transparent;
  border: none;
  font-weight: 400;

  &:hover {
    text-decoration: underline;
  }

  &[data-disabled=true] {
    opacity: 0.5;
    cursor: not-allowed;

    &:hover {
      text-decoration: none;
    }
  }
}
