$padding-size: 15px;

.wz-c-transactions-details {
  &-card {
    background-color: #fff;
    border: 1px solid var(--color-border-grey);
    border-radius: 4px;
  }

  &-header {
    padding: 12px $padding-size;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;

    &__expand-icon {
      transition: transform 0.3s;

      &[data-inverted=true] {
        transform: rotate(180deg);
      }
    }
  }

  &-content {
    padding: $padding-size;
  }

  &-splitter {
    border-top: 1px solid var(--color-border-grey);

    &[data-dashed=true] {
      border-top-style: dashed;
    }
  }
}
