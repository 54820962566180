.wz-c-form-field-readonly-tooltip {
  &[data-inline=true] {
    display: inline-block;
  }

  .wz-tooltip-without-margin {
    display: block !important;
  }

  .wz-popin__container {
    display: block !important;
  }
}
