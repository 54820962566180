.wz-c-cancellation-details {
  padding-top: 10px;
  padding-left: 28px;
  padding-right: 32px;
}

.wz-c-cancellation-information-table {
  width: 100%;

  & > tr:not(:last-child) > td {
    padding-bottom: 16px;
  }

  & > tr {
    vertical-align: top;
  }
}

.wz-c-cancellation-information-row {
  &__name {
    font-weight: 500;
    width: 40%;
    color: var(--color-secondary-text);
  }

  &__value {
    color: var(--color-secondary-text);
    padding-left: 20px;
  }
}
