@import "../../../../styles/mixins";


.contentWrapper {
  align-items: flex-start;

  & > * {
    flex-basis: 50%;
  }

  @include tablet {
    flex-direction: column;
    align-items: stretch;
  }
}
