.medium {
  font-weight: 500;
}
/* LabelHeader */
.initials {
  display: inline-block;
  background-color: #ced0da !important;
  color: #000 !important;
  margin-right: 1.1em;
  cursor: none;
  min-width: 46px;
}

.ticket-card {
  border-radius: 5px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.33);
  margin-bottom: 15px;
}

.ticket-card-header {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 15px 20px 20px 20px;
  background-color: #fffef5;
  border-bottom: 1px solid #CED0DA;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ticket-card-header-name-infos {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.ticket-card-header-name-infos > span{
  font-size: 18px;
  font-weight: 500;
}

.ticket-card-content {
  background-color: #FFF;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.ticket-card-content-more-infos {
  padding: 15px 20px 20px 20px;
  border-bottom: 1px solid #CED0DA;
  cursor: pointer;
}

.ticket-card-content-attendee-infos {
  padding: 15px 20px 20px 20px;
  border-bottom: 1px solid #CED0DA;
}

.ticket-card-content-buyer-infos {
  padding: 15px 20px 20px 20px;
}

.ticket-card-content-buyer-infos-title {
  font-size: 16px;
  font-weight: 500;
  color: #939394;
}

/* LabelDetailsContainer */
.label-details__container {
  display: inline-flex;
  flex-wrap: wrap;
}
.label-details__title {
  color: #878c9b;
}
.label-details__container > * {
  flex: 0 1 auto;
  margin-right: 3em;
}

@media screen and (max-width: 600px) {
  .label-details__container > * {
    margin-right: 2em;
  }
}

/* Forms */
.table-form-answers {
  width: 50%;
}

@media screen and (max-width: 600px) {
  .table-form-answers {
    width: 100%;
  }
}

.wz-p-ticket_details-td-field {
  padding: 8px 0;
  max-width: 50px;
  font-weight: 500;
}

.wz-p-ticket_details-td-answer {
  padding: 8px 0 8px 40px;
  max-width: 100px;
}
