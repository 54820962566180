.root {
  width: 100%;

  th {
    color: var(--color-secondary-text);
    font-weight: 400;
  }

  th:last-child {
    width: 120px;
    text-align: right;
  }
}
