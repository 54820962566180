.option {
  width: 100% !important;
  margin: 0 !important;
}

.optionChecked {
  :global(label) {
    border: 1px solid #000 !important;
    background-color: #FFFEF5 !important;
    box-shadow: none !important;
  }
}

.optionLabel {
  font-weight: 500;
}

.optionDescription {
  font-weight: 400;
  color: #878C9B;
}
