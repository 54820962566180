.table {
  :global(thead) {
    display: none !important;
  }

  :global(tbody tr) {
    height: 40px !important;
  }

  :global(td), :global(th), :global(.wz-tablerow td) {
    padding: 0 8px !important;
  }

  :global(tr), :global(.wz-tablerow) {
    border-bottom: none;
  }

  :global(.wz-field-block) {
    margin: 0;
  }
}

:global(.wz-tablerow__draggable) {
  z-index: 100000;
}
