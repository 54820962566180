.actions {
  align-self: center;
}

.title {
  font-size: 12px;
  color: var(--color-secondary-text);
  margin-bottom: 8px;
}

.action {
  background-color: #F8F9FC;
  color: #5E636E;
  width: 40px !important;
  height: 40px !important;
  font-size: 16px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &::before {
    position: relative;
    top: 4px;
  }
}

.fullWidth {
  width: 100%;
}
