.products-warning-options {
  .wz-c-smart-table-min-width {
    min-width: 100px;
  }
  .wz-optional-section {
    margin-bottom: 0px;
    margin-top: 10px;
  }
}

.smart-table {
  .wz-c-smart-table-min-width {
    min-width: 600px;
}
  .wz-table tr, .wz-tablerow {
    border-bottom: 1px solid #EDEDED;
}
  .wz-table thead th {
    font-size: 14px;
    font-weight: bold;
    color: #1b1c1d;
    background-color: #EDEDED;
}
}

.buttons {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  padding-left: 55px;
  .wz-framebutton__label {
    display: grid;
    place-items: center;
    margin-top: 2px;
  }
}

.modal-custom-fields {
  .wz-container-modal .wz-modal .wz-title-modal {
    padding-bottom: 1px;
}
}
