.wz-daphne__walletsImports__downloadTemplateContainer {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 15px;
  cursor: pointer;
  & > * {
    color: #878C9B;
  }
  &:hover > * {
    color: #000;
  }
}
.wz-daphne__walletsImports__dropzoneContainer .dropZoneStyle {
  background-color: #EDEDED;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.wz-daphne__walletsImports__dropzoneContainer .typeFile{
  font-size: 12px;
}
.wz-daphne__walletsImports__dropzoneLabel {
  display: flex;
  flex-direction: column;
}
.wz-daphne__walletsImports__sectionWithMarginTop {
  margin-top: 20px;
}
.wz-daphne__walletsImports__ctaContainer {
  margin-top: 25px;
  display: flex;
  gap: 15px;
}
.wz-daphne__walletsImports__errorReportContainer {
  margin-top: 15px;
}
.wz-daphne__walletsImports__errorReportContainer .wz-optional-section__label {
  margin-bottom: 0;
}
.wz-daphne__walletsImports__loaderResults {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 15px;
}
.wz-daphne__walletsImports__loaderResults p {
  text-align: center;
}
.wz-daphne__walletsImports__loaderResults span {
  display: block;
}
.wz-daphne__walletsImports__successResults .wz-message {
  margin-top: 0;
}
.wz-daphne__walletsImports__step2Message {
  margin-top: 0;
}
.wz-daphne__walletsImports__checkMessages,
.wz-daphne__walletsImports__analysisList,
.wz-daphne__walletsImports__resultMessages {
  padding: 0;
  margin: 0;
  margin-top: 20px;
  
  li > span {
    vertical-align: super;
  }
  .wz-status {
    margin-left: 0;
  }
  li {
    list-style: none;
  }
  li:not(:first-child) {
    margin-top: 8px;
  }
}
.wz-daphne__walletsImports__analysisList {
  margin-top: 0;
  
  li {
    &:first-child {
      height: 24px;
    }
    &:not(:first-child) {
      margin-top: 10px;
    }
    > span {
      vertical-align: text-bottom;
    }
    .wz-chip-icon-container {
      display: inline-block;
      
      width: 20px;
      height: 20px;

      margin-right: 7px;

      .wz-chip-icon {
        top: 0;
        left: 1px;
      }
    }
  }
}
.wz-formstep__edit--hidden {
  display: none;
}
.wz-daphne__walletsImports__examples {
  max-width: 33vw;
}