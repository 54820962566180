.wz-daphne__form-field-color-picker {
  display: flex;
  justify-items: center;
  gap: 10px;
  gap: 0.625rem;
}

.wz-daphne__form-field-color-picker__input {
  display: flex;
}

.wz-daphne__form-field-color-picker__label {
  display: flex;
  align-items: center;
}
