/* Start fuckin' workarounds for shitty Nacre components */
.wz-daphne__widget-general-cashless-section__nacre-wrapper .wz-input-number {
  text-align: left !important;
}

.wz-daphne__widget-general-cashless-section__nacre-wrapper .wz-input-icon__left,
.wz-daphne__widget-general-cashless-section__nacre-wrapper .wz-input-icon__right {
  display: none !important;
}
/* End fuckin' workarounds for shitty Nacre components */
