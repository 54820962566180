.btn {
  --button-color: black;
  transition: background-color 0.2s;

  background-color: transparent !important;
  border-style: solid !important;
  border-width: 1px !important;
  border-color: var(--button-color) !important;
  color: var(--button-color) !important;
  overflow: hidden;

  &[disabled] {
    --button-color: #C2C9D6;
  }

  &:hover {
    background-color: #EFF1F9 !important;
  }

  i {
    --primary-color: 0, 0, 0;
  }
}
