$mobile-breakpoint: 600px;
$tablet-breakpoint: 960px;
$desktop-breakpoint: 1441px;

@mixin mobile {
  @media (max-width: $mobile-breakpoint) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: $tablet-breakpoint) {
    @content;
  }
}
