/* CropImage */
.wz-crop-image {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
  .wz-crop-image__border {
    border: 10px #ededed solid;
    border-radius: 5px;
    background-color: #ededed;
    border-bottom: 6px #ededed solid;
    .wz-crop-image__preview {
      max-height: 400px;
    }
  }
}

/* CropButtonPanel */
.wz-crop-panel {
  display: flex;
  justify-content: center;
  .wz-crop-panel__container {
    display: flex;
    .wz-crop-panel__container-left {
      display: flex;
      margin-right: 30px;
      .wz-crop-panel__container-toggle-ratio {
        margin-right: 30px;
        margin-top: 0;
        .wz-crop-panel__toggle {
          height: 35px;
          display: flex;
          align-items: center;
        }
      }
      .wz-crop-panel__container-size {
        display: flex;
        .wz-crop-panel__container-counter {
          margin-top: 0;
          width: 120px;
          min-width: 120px;
          margin-right: 10px;
          &:last-child {
            margin-right: 0;
          }

          .wz-ui-input.wz-ui-input-number {
            height: 37px;
          }
        }
      }
    }
    .wz-crop-panel__container-right {
      display: flex;
      .wz-field-block {
        margin: 0;
        .wz-crop-panel__button {
          width: 37px;
          height: 37px;
          padding: 9px;
          margin-right: 10px;
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 960px) {
  .wz-crop-panel {
    .wz-crop-panel__container {
      .wz-crop-panel__container-left {
        margin-right: 20px;
        .wz-crop-panel__container-toggle-ratio {
          margin-right: 20px;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .wz-crop-panel__container {
    flex-direction: column;
    .wz-crop-panel__container-left {
      margin-right: 0;
    }
  }

}

@media screen and (max-width: 400px) {
  .wz-crop-panel {
    .wz-crop-panel__container {
      .wz-crop-panel__container-left {
        flex-direction: column;
        .wz-crop-panel__container-toggle-ratio {
          display: flex;
          .wz-crop-panel__toggle-ratio-label {
            margin: 0 15px 0 0;
          }
          .wz-crop-panel__toggle {
            height: auto;
            display: inline-flex;
          }
        }
      }
    }
  }
}
/* End CropButtonPanel */