.wz-daphne__scenario-action_yellow-select {
  &:not([data-has-error=true]) {
    .wz-input.wz-input-text, .wz-input.wz-input-number {
      border-color: #FAA43E !important;
      color: #FAA43E;
    }

    .wz-input-value {
      color: #FAA43E;
    }
  }
}

.wz-daphne__scenario-action_unusual_warning {
  color: #FAA43E;
  font-size: 12px;
  margin-bottom: 10px;
  display: block;
}
.wz-daphne__scenario-action_amountError li {
  font-size: 12px;
  margin-bottom: 10px;
}
