.wz-daphne__common__list-table-header-object-count-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  padding-bottom: 15px;
  position: relative;
}

.wz-daphne__common__list-table-header-object-count__search {
  flex-grow: 2;
  padding-right: 15px;
}

.wz-daphne__common__list-table-header-object-count__label {
  padding-right: 15px;
}

.wz-total-result {
  display: none;
}
