.wz-c-custom-filter {
  .wz-swipe-screen {
    overflow: hidden !important;
  }

  .wz-ui-input {
    margin-right: 0 !important;
  }
  .wz-block-scroll {
    //overflow-x: hidden;
  }
}
