.wz-c-button-dropdown {
  height: 40px;
  border-radius: 6px;
  font-size: 14px;

  &__separator {
    background-color: #5E636E;
    width: 1px;
    height: 100%;

    &[data-disabled=true] {
      opacity: 0.3;
    }
  }

  button[disabled] {
    background-color: #F5F6F8;
    color: #B0B3BC;
    cursor: not-allowed;
  }
}
