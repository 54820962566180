@import "../../../../../../styles/mixins";

.subLabel {
  font-size: 12px;
  color: #878C9B;
}

.path {
  display: flex;
  gap: 40px;
  justify-content: space-between;

  .pathArrow {
    position: relative;
    top: 43px;
  }

  @include mobile {
    flex-direction: column;

    .pathArrow {
      transform: rotate(90deg);
      top: 0;
    }
  }
}

.pathItem {
  flex-basis: 50%;
}
