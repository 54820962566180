$bulletSize: 32px;
$gap: 8px;
$emptyBulletSize: 7px;

@mixin emptyBullet {
    width: $emptyBulletSize;
    height: $emptyBulletSize;
    border-radius: 50%;
    background-color: var(--color-border-grey);
}

.root {
  &:not(&:last-of-type) {
    padding-bottom: 20px;
  }

  &:last-of-type {
    padding-bottom: $emptyBulletSize;

    .bulletWrapper {
      &::after {
        @include emptyBullet();
        content: "";
        position: absolute;
        bottom: 0;
      }
    }
  }

  position: relative;
}

.bulletWrapper {
  width: $bulletSize;
  display: flex;
  justify-content: center;

  &::before {
    z-index: 0;
    content: "";
    height: 100%;
    position: absolute;
    top: 0;
    left: calc($bulletSize / 2);
    border-left: 1px solid var(--color-border-grey);

    transform: translateX(-50%);
  }

  &.dashed {
    &::before {
      border-left-style: dashed;
    }
  }
}

.bulletContent {
  width: $bulletSize;
  height: $bulletSize;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #F8F9FC;
  border: 1px solid var(--color-border-grey);
  z-index: 1;
  overflow: hidden;

  :global {
    i.wz-font {
      color: #5E636E !important;
    }

    i.wz-font.wz-font-margin {
        margin: 0 !important;
    }
  }

}

.emptyBullet {
  @include emptyBullet();
}

.content {
  padding-left: calc($bulletSize) + $gap;
}
