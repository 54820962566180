.wz-daphne__bank-bag-price-informer {
  &__wrapper {
    background-color: #EDEDED;
    border-radius: 6px;
    padding: 15px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 10px;

    transition:
      background-color 0.3s ease-out,
      border 0.3s ease-out;

    &--red {
      border: 1px solid #F4080A;
      background-color: #FEEFEF;
    }

    &--green {
      border: 1px solid #CED0DA;
      background-color: #FFFEF5;
    }

    &--orange {
      border: 1px solid #FAA43E;
      background-color: #FFFEF5;
    }
  }

  &__block {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__title {
    font-weight: 500;
  }

  &__name {
    font-size: 14px;
    transition: color 0.3s ease-out;
    display: flex;
    align-items: center;

    &--red {
      color: #F4080A;
    }

    &--orange {
      color: #FAA43E;
    }
  }

  &__price {
    font-size: 14px;
    font-weight: normal;
    transition: color 0.3s ease-out;

    &--red, &--green, &--orange {
      font-weight: 500;
    }

    &--red {
      color: #F4080A;
    }

    &--orange {
      color: #FAA43E;
    }
  }
}
