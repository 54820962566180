@import "src/styles/mixins";

.images-library-category {
  overflow-y: auto;
  min-height: 55vh;
  flex-grow: 1;

  @include mobile {
    height: auto;
  }
}
