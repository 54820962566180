.row {
  :global {
    .wz-field-block {
      margin: 0 !important;
    }

    .wz-tablecell {
      padding: 8px 16px !important;
    }
  }
}

.hidden {
  display: none !important;
}
