.wz-daphne__widget-interface-colors-section__basic-options,
.wz-daphne__widget-interface-colors-section__advanced-options {
  display: flex;
  flex-direction: column;
  gap: 16px;
  gap: 1rem;
  justify-items: center;
  align-items: flex-start;
}

.wz-daphne__widget-interface-colors-section .wz-optional-form {
  margin: 16px 0px 10px 0px;
  margin: 1rem 0rem 0.625rem 0rem;
}

.wz-daphne__widget-interface-colors-section__field {
  display: flex;
  justify-items: center;
  gap: 10px;
  gap: 0.625rem;
}

.wz-daphne__widget-interface-colors-section__field__input {
  display: flex;
}

.wz-daphne__widget-interface-colors-section__field__label {
  display: flex;
  align-items: center;
}
