.cell {
  padding: 14px 10px;
}

.table {
  width: 100%;

  :global(tr) {
    :global(td:first-child) {
      width: 25%;
    }

    :global(td:nth-child(2)) {
      width: 25%;
    }
  }
}

.row {
  display: flex;
  align-items: center;
  transition: all 0.25s ease;
  background-color: white;

  .cell:first-child {
    flex-basis: 25%;
  }

  .cell:nth-child(2) {
    flex-basis: 25%;
  }

  .cell:nth-child(3) {
    flex-basis: 25%;
  }

  .cell:nth-child(4) {
    flex-basis: 25%;
  }
}

.headerRow {
  color: #878C9B;
  font-size: 12px;
}
