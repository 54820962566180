@import '@weezevent/nacre/lib/assets/styles/core.scss';
@import '~semantic-ui-css/semantic.css';
@import "_variables.scss";
@import "~bootstrap-sass/assets/stylesheets/_bootstrap.scss";
@import "_bootswatch.scss";
@import '~@fortawesome/fontawesome-free/css/all.css';

html {
  overflow-x: hidden;
  height: initial !important;
}

.browsehappy {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

// Force fontawsome font on .fa even in nacre mode otherwise the icons
// are not displayed correctly:
// nacre tells the browser to use Grapihk everywhere but the fontawsome private
// codepoints are obviously not defined in Graphik so :boom:
.wz-nacre-content .fa {
  font-family: FontAwesome;
}

/* Custom page header */
.header {
  border-bottom: 1px solid #e5e5e5;

  /* Make the masthead heading the same height as the navigation */
  h3 {
    margin-top: 0;
    margin-bottom: 0;
    line-height: 40px;
    padding-bottom: 19px;
  }
}

/* Custom page footer */
.footer {
  padding-top: 19px;
  color: #777;
  border-top: 1px solid #e5e5e5;
  text-align: center;
}

.footer-rental {
  position: absolute;
  height: 60px;
  bottom: 0;
}

.container-narrow > hr {
  margin: 30px 0;
}

/* To hide an automatic header-bar for extending rows*/
.dashboard-bank .ng-table-group-header, .article-panel .ng-table-group-header, .dashboard-sales .ng-table-group-header, .article-panel .ng-table-group-header{
  display: none;
}

.dashboard-bank .ng-table-sort-header th:nth-child(n+2), .dashboard-sales .ng-table-sort-header th:nth-child(n+2){
  text-align: right;
}

.dashboard-bank .ng-table-sort-header th:nth-child(0n+1), .dashboard-sales .ng-table-sort-header th:nth-child(0n+1){
  background-color: rgba(255, 255, 255, 0.9);
}

.dashboard-bank.muted .ng-table-sort-header th:nth-child(0n+2), .dashboard-sales.muted .ng-table-sort-header th:nth-child(0n+2){
  color: $text-muted;
}

.capitalize::first-letter {
  text-transform: uppercase;
}

.order-to-be-paid {
  background-color: $gray-lighter;
  border: 1px solid $gray-light;
  padding: 5px 15px 5px 15px;
  color: $gray;
  white-space: nowrap;
}

.order-status {
  display: inline-block;
  padding-top: 5px;
}

/* To hide an automatic header-bar for filtering*/
.user-list .ng-table-filters {
  display: none;
}

/* Responsive: Portrait tablets and up */
@media screen and (min-width: 768px) {
  .container {
    max-width: 730px;
  }

  /* Remove the padding we set earlier */
  .header,
  .marketing,
  .footer {
    padding-left: 0;
    padding-right: 0;
  }
  /* Space out the masthead */
  .header {
    margin-bottom: 30px;
  }
  /* Remove the bottom border on the jumbotron for visual effect */
  .jumbotron {
    border-bottom: 0;
  }
}

.ng-cloak, .x-ng-cloak {
  display: none !important;
}

#loader {
  padding: 40px 20px;
}

.footer a, .footer a:hover {
  text-decoration: none;
  color: inherit;
}

.dropdown-menu li a.current {
  font-weight: bold;
}

.spinner {
  padding: 10px 10px 0px 5px;
}
.header-spinner {
  display: inline-block;
  padding-top: 5px;
  padding-left: 7px;
}

.dragover img {
  border: dotted 3px red;
}


.notifications.top-right {
  top: 50px;
  z-index: 10000;
}

#tableau-articles button {
  width: 19%;
  height: 16.67%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  white-space: normal;
}

.small-button {
  float: none;
  display: inline-block;
  color: black;
  padding-top: 6px;
}

.grouped-controls {
  display: inline-block;
  width: 48%;
}

// Required by notify js plugin which is for Bootstrap 2
.notifications .alert {
  padding: 8px 35px 8px 14px;
}
.notifications .alert .close {
  position: relative;
  top: -2px;
  right: -21px;
  line-height: 20px;
}

// Fix unwanted center align
.ng-table th {
  text-align: left;
}

.pointer {
  cursor: pointer;
}

.button-link {
  background: none!important;
  border: none;
  padding: 0!important;
  text-decoration: underline;
  cursor: pointer;
}

.color-brand-primary {
  color: $brand-primary
}

tr.cancelled td {
  text-decoration: line-through;
}

tr.menu-choice td {
  color: #9b9797;
  border: 0 !important;
}

.cancelled {
  text-decoration: line-through;
}

// based on sb-admin2

h1 {
    color: #37474f;
}

#wrapper {
    width: 100%;
    min-height: 100vh;
}

#page-wrapper {
    padding: 0 15px;
    min-height: 568px;
}

@media(min-width:768px) {
    #page-wrapper {
        position: inherit;
        padding: 0 30px;
    }

    .page-wrapper-margin {
        margin: 0 0 0 $sidebar-width;
    }
}

.panel {
    border-radius: 0;
    -webkit-box-shadow: 0;
    box-shadow: 0;
}

.panel-heading {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.navbar-header {
    width: $sidebar-width
}

.navbar-toggle .icon-bar {
  background-color: $text-color;
}

.navbar .mode-switch {
  display: inline-block;
}

.navbar {
    margin-bottom: 0;
    height: $topmenu-height;
}

.navbar-header:hover {
  background-color: darken($brand-primary, 10%);
}

.navbar-brand {
    width: $sidebar-width;
    padding: 0;
    background-image: url("../images/weezpay-logo-C-bichro04.svg");
    background-position-x: -30px;
    background-position-y: -25px;
    background-size: 200px;
    background-repeat: no-repeat;
}

.navbar-brand img {
    height: 100%;
}

.navbar-static-top {
    border: 0;
}

.sidebar-nav {
    top: 0;
    bottom: 0;
    background: $brand-primary;
}

.sidebar-nav .nav > li {
    background-color: $menu-background-inverse;
}

.sidebar-nav .nav > li > a, .sidebar-nav .nav > li > a:focus {
    padding-left: 12px + 16px;
    color: #555555;
}

.sidebar-nav .nav > li > a:hover, .sidebar-nav .nav > li > a.active:hover {
    background-color: $menu-background;
    color: $brand-primary;
}

.sidebar-nav .nav > li > a.active {
    background-color: $menu-background;
    color: $brand-primary;
}

.sidebar-nav .nav > li.title {
    font-weight: bold;
    padding: 10px 12px;
    border-top: 2px solid $menu-background;
}

.sidebar-nav .nav > li.title:nth-child(1) {
  border-top-width: 0;
}

.navbar-primary {
    background-color: $brand-primary;
}

.home {
  text-align: center;
}

.home a i {
  font-size: 2em;
  color: $text-color;
}

.home a p {
  visibility: hidden;
}

.home a:hover p {
  visibility: visible;
}

.home .btn-primary {
  margin-top: 10px;
  width: 100%;
}

#fundation {
  position: absolute;
  right: 0px;
  /*TODO margin: 20px 30px 0px 0px;*/
}

#fundation li {
  list-style-type: none;
  margin: 0;
}

.navbar-top-links {
    float: right;
}

.navbar-top-links > li {
    display: inline-block;
}

.navbar-top-links > li:hover {
}

.navbar-top-links li.dropdown a.dropdown-toggle {
    border: 0;
    padding-right: 10px;
    background: transparent;
    border: 1px solid transparent;

    &:hover {
      background-color: darken($brand-primary, 10%);
    }
}

.navbar-top-links li.dropdown {
    list-style: none;
    margin-right: -3px;
}

.navbar-top-links li.dropdown:last-child {
    margin-right: 0;
}

.navbar-top-links li a {
    padding: 10px 0px 0px 10px;
}

.navbar-top-links .dropdown-menu li {
    display: block;
}

.navbar-top-links .dropdown-menu li:last-child {
    margin-right: 0;
}

.navbar-top-links .dropdown-menu li a {
    padding: 3px 20px;
    min-height: 0;
}

.navbar-top-links .dropdown-menu li a div {
    white-space: normal;
}

.navbar-top-links .dropdown-messages,
.navbar-top-links .dropdown-tasks,
.navbar-top-links .dropdown-alerts {
    width: 310px;
    min-width: 0;
}

.navbar-top-links .dropdown-messages {
    margin-left: 5px;
}

.navbar-top-links .dropdown-tasks {
    margin-left: -59px;
}

.navbar-top-links .dropdown-alerts {
    margin-left: -123px;
}

.navbar-top-links .dropdown-user {
    right: 0;
    left: auto;
}

.navbar-top-links .open > .btn-default.dropdown-toggle,
.navbar-top-links .btn-default,
.navbar-top-links .btn-default:active,
.navbar-top-links .btn-default:active:hover,
.navbar-top-links .btn-default:active:focus,
.navbar-top-links .btn-default:active.focus,
.navbar-top-links .btn-default.active:hover,
.navbar-top-links .btn-default.active:focus,
.navbar-top-links .btn-default.active.focus,
.navbar-top-links .btn-default.dropdown-toggle:hover,
.navbar-top-links .btn-default.dropdown-toggle:focus,
.navbar-top-links .btn-default.dropdown-toggle.focus {
    background: transparent;
}

.sidebar .sidebar-nav.navbar-collapse {
    padding-right: 0;
    padding-left: 0;
}

.sidebar .sidebar-search {
    padding: 15px;
}


.arrow {
    float: right;
}

.fa.arrow:before {
    content: "\f104";
}

.active>a>.fa.arrow:before, .activecategory>a>.fa.arrow:before {
    content: "\f107";
}

.nav-second-level li,
.nav-third-level li {
    border-bottom: 0!important;
}

.nav-second-level li a {
    padding-left: 37px;
}

.nav-third-level li a {
    padding-left: 52px;
}

@media(min-width:768px) {
    .sidebar {
        z-index: 1;
        position: absolute;
        width: $sidebar-width;
    }

    .navbar-top-links .dropdown-messages,
    .navbar-top-links .dropdown-tasks,
    .navbar-top-links .dropdown-alerts {
        margin-left: auto;
    }
}

.btn-white {
  background-color: #DADADA;
  border-color: #ebebeb;
  color: #000;
}

.btn-outline {
    color: inherit;
    background-color: transparent;
    transition: all .5s;
}

.btn-primary.btn-outline {
    color: #428bca;
}

.btn-success.btn-outline {
    color: #5cb85c;
}

.btn-info.btn-outline {
    color: #5bc0de;
}

.btn-warning.btn-outline {
    color: #f0ad4e;
}

.btn-danger.btn-outline {
    color: #d9534f;
}

.btn-primary.btn-outline:hover,
.btn-success.btn-outline:hover,
.btn-info.btn-outline:hover,
.btn-warning.btn-outline:hover,
.btn-danger.btn-outline:hover {
    color: #fff;
}

.btn-link:focus,.btn-link:active {
   outline: none !important;
   box-shadow: none;
}

.btn-long-label {
  white-space:normal !important;
}

.panel .slidedown .glyphicon{
    margin-right: 5px;
}

.login-panel {
    margin-top: 25%;
}

.flot-chart {
    display: block;
    height: 400px;
}

.flot-chart-content {
    width: 100%;
    height: 100%;
}

table.dataTable thead .sorting,
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc,
table.dataTable thead .sorting_asc_disabled,
table.dataTable thead .sorting_desc_disabled {
    background: 0 0;
}

table.dataTable thead .sorting_asc:after {
    content: "\f0de";
    float: right;
    font-family: fontawesome;
}

table.dataTable thead .sorting_desc:after {
    content: "\f0dd";
    float: right;
    font-family: fontawesome;
}

table.dataTable thead .sorting:after {
    content: "\f0dc";
    float: right;
    font-family: fontawesome;
    color: rgba(50,50,50,.5);
}

.btn-circle {
    width: 30px;
    height: 30px;
    padding: 6px 0;
    border-radius: 15px;
    text-align: center;
    font-size: 12px;
    line-height: 1.428571429;
}

.btn-circle.btn-lg {
    width: 50px;
    height: 50px;
    padding: 10px 16px;
    border-radius: 25px;
    font-size: 18px;
    line-height: 1.33;
}

.btn-circle.btn-xl {
    width: 70px;
    height: 70px;
    padding: 10px 16px;
    border-radius: 35px;
    font-size: 24px;
    line-height: 1.33;
}

.show-grid [class^=col-] {
    padding-top: 10px;
    padding-bottom: 10px;
    border: 1px solid #ddd;
    background-color: #eee!important;
}

.show-grid {
    margin: 15px 0;
}

.huge {
    font-size: 40px;
}

.panel-green {
    border-color: #5cb85c;
}

.panel-green .panel-heading {
    border-color: #5cb85c;
    color: #fff;
    background-color: #5cb85c;
}

.panel-green a {
    color: #5cb85c;
}

.panel-green a:hover {
    color: #3d8b3d;
}

.panel-red {
    border-color: #d9534f;
}

.panel-red .panel-heading {
    border-color: #d9534f;
    color: #fff;
    background-color: #d9534f;
}

.panel-red a {
    color: #d9534f;
}

.panel-red a:hover {
    color: #b52b27;
}

.panel-yellow {
    border-color: #f0ad4e;
}

.panel-yellow .panel-heading {
    border-color: #f0ad4e;
    color: #fff;
    background-color: #f0ad4e;
}

.panel-yellow a {
    color: #f0ad4e;
}

.panel-yellow a:hover {
    color: #df8a13;
}

.panel-primary {
    border-color: $brand-primary;
    .panel-heading {
      color: black;
    }
}

.sidebar .panel {
    margin: 15px;
}

.sidebar .panel .panel-heading, .sidebar .panel .panel-body {
    padding: 10px;
}

.sidebar .panel .panel-heading {
    background-color: $brand-primary;
    color: #fff;
}

.sidebar .panel .panel-body {
    background-color: #231F20;
    color: #333;
}

.sidebar .panel .panel-body ul {
    list-style-type: square;
    padding-left: 15px;
    line-height: 1.3em;
}

// Weezevent tables

table.listTable{
  width: 100%;
  font-size: 14px;
  color: #666666;
}
table.listTable tr {
    background: #fff
}
table.listTable tr td{
  padding: 5px 15px 0 15px;
  height: 40px;
  line-height: 40px;
  vertical-align: middle
}
table.listTable tr.odd td{
  background: #F5F5F5;
}
table.listTable tr.even td{
  background: #F2F2F2;
}
table.listTable tr.titre th,
table.listTable tr.titre td{
font-weight:normal;
}
table.listTable tr.titre th{
  padding: 10px 15px;
  text-align: left;
  height: 30px;
  line-height: 30px;
  vertical-align:middle;
  text-transform: uppercase;
}
//Commentaires utilisateurs
.comment {
  white-space: pre-wrap;
  word-break: normal;
  max-width : 300px;
}

// Drop box

.drop-box {
  background: #F8F8F8;
  border: 5px dashed #D0D0D0;
  height: 100%;
  min-height: 60px;
  text-align: center;
  padding-top:15px;
  margin: 10px;
}

.drop-box.selected {
  background: #8DFF8D;
  border: 5px dashed #79d87A;
  transition: background 400ms;
}

.dragover {
  border: 5px dashed #DEDEDE;
}

// Choix d'icônes

.icon-thumbnails {
  text-align: center;
}

.icon-thumbnail {
  display: inline-block;
  margin: 0 5px;
}

.icon-selected {
  background-color: #000;
}

.icon-selected > i {
  filter: invert(100%);
}

// Banque d'images
.img-bank-grid {
  min-height: 300px;
}

.img-bank-grid > div {
  margin: 8px 0px;
}

.img-bank-grid img {
  height: 75px;
  max-width: 75px;
  width: auto;
  display: block;
}

.img-bank-pagination {
  margin-top: 15px;
  margin-right: 15px;
}

.top40 {
  margin-top: 40px;
}

.top20 {
  margin-top: 20px;
}

.top14 {
  margin-top: 14px;
}

.top10 {
  margin-top: 10px;
}

.top5 {
  margin-top: 5px;
}

.right5 {
  margin-right: 5px;
}

.left5 {
  margin-left: 5px;
}

.left10 {
  margin-left: 10px;
}

.paddingleft1 {
  padding-left: 1em !important;
}

.paddingleft2 {
  padding-left: 2em !important;
}

.fix-ui-select-padding-in-form-group {
  padding-left: 0px;
}

.fix-ui-select-background-color .btn-default {
  background-color: white;
}

.fix-ui-select-background-color:disabled .btn-default {
  background-color: lightgrey;
}


.white {
  color: white;
}

.nohover {
  opacity: 1 !important;
}


.modal-center-lg {
  left: 50%;
  transform: translateX(-50%);
}


@media print {
  .modal-content, .modal-header, .modal-body, .modal-dialog, .modal {
    box-shadow: 0;
    -webkit-box-shadow: 0;
    border: 0;
  }

  .form-inline input.form-control, div input.form-control {
    border: 0;
  }

  // prevent appending empty page to pdf
  #wrapper {
    display: none;
  }

  // make sure modal content is not truncated to a single pdf page
  .modal-open {
    overflow: visible;
  }
  .modal {
    position: static;
  }
}

.form-control-react-input > div {
  display: block;
  width: 100%;
  height: 42px;
  font-size: 14px;
  line-height: 1.42857;
  color: #333333;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.img-product {
  max-height: 500px;
  width: 100px;
}

.td-sold-out-checkbox {
  vertical-align: middle !important;
}

.td-input-number {
  vertical-align: middle !important;

  input {
    width: 100%;
  }
}

.table-borderless > tbody > tr > td,
.table-borderless > tbody > tr > th,
.table-borderless > tfoot > tr > td,
.table-borderless > tfoot > tr > th,
.table-borderless > thead > tr > td,
.table-borderless > thead > tr > th {
    border: none;
}

table.listTable tr.titre img,
table.listTable tr.titre input{
  vertical-align: middle;
}
table.listTable tr.titre img{
  cursor: pointer;
}
table.listTable tr .action{
  text-align: center!important;
}
table.listTable tr .action a,
table.listTable tr .action span.deactivated,
table.listTableForm tr .action a,
table.listTableForm tr .action span.deactivated{
  text-decoration: none;
  color: #9b9b9b;
}
table.listTable tr .action a:hover{
  color: #00c3ff;
}
table.listTable tr td.intitule{
  width: 440px;
  height: 40px;
}
table.listTable tr td.prix,
table.listTable tr td.vente,
table.listTable tr td.reserve,
table.listTable tr td.quantite,
table.listTable tr td.date,
table.listTable tr td.id_ev{
  color: #333333;
  font-size: 14px;
  vertical-align: top;
}
table.listTable tr td.toggleRowField{
  cursor:pointer;
}
table.listTable thead th{
  background-color: $brand-primary;
  color: #FFF;
  line-height: 30px;
  padding: 5px 15px 2px 15px;
  text-align: left;
}
table.listTable .even td{
  background: #ffffff!important;
}
table.listTable .odd td{
  background: #F2F2F2!important;
}

table.listTable tr.line:nth-child(even){
    background: #F2F2F2!important;
}
table.listTable tr.line:nth-child(odd){
    background: #ffffff!important;
}

.dataTables_info,
.dataTables_filter,
.dataTables_length,
.dataTables_empty{
  display: none;
}
span.code{
  color: #999;
  font-size: 14px;
}
/*** TITRES ***/

td.intitule span.title{
  color: #333333;
  font-weight: normal;
}
td.intitule span.subtitle{
  color: #999;
  font-size: 9px;
  font-weight: normal;
  letter-spacing: 0;
  line-height: 10px;
  margin-top: -4px;
  text-transform: none;
  padding-bottom: 4px;
}
td.intitule span{
  display: block;
}

.listTable tr  td.action .send:hover{
  opacity:0.8;
}

/*** STATS ***/

table.stats_table{
  width:100%;
  padding:0;
  line-height:20px;
  border-collapse:collapse;
  font-size:12px;
  color:#333;
  border:0;
}
table.stats_table tr{
  border:0;
}
table.stats_table td{
  vertical-align:top;
}
table.stats_table .border{
  border: 1px solid #DDD;
}
.diagramSwitch{
  font-size:12px;
  float:right;
  font-weight:normal;
  text-transform:none;
  color:#999;
}
.diagramSwitch a{
  color:#999;
}
.diagramSwitch a:hover{
  color:$brand-primary;
}
.diagramSwitch a.active{
  text-decoration:underline;
}

h2.page-header {
  margin: 20px 0 21px;
}

h2.popup-header {
  margin-top: 10px;
}

.navbar-primary .btn-default {
  color: $btn-dark-color;
  background-color: $btn-primary-bg;
  border: 0px solid transparent;
  height: $topmenu-height;
  border-radius: 0;
  padding-top: 15px;

  &:focus,
  &.focus {
    background-color: $menu-background;
  }

  &:hover,
  &.active:hover {
    background-color: darken($brand-primary, 10%);
  }

  &:active,
  &.active,
  .open > &.dropdown-toggle {
    background-color: $menu-background;
    box-shadow: none;
  }
}

@media(max-width:$screen-md) {
  .navbar-primary .btn-default span {
    display: none;
  }
}


// Mobile view
@media screen and (max-width:768px){
  .navbar-header {
    width: 100px;
    margin: 0 auto 0 80px;
    height: $topmenu-height;
  }

  .navbar-brand {
    float: none;
    display: inline-block;
    background-image: url("../images/weezpay-logo-B-nb01.svg");
    width: 100px;
    background-position-x: -40px;
    background-position-y: -20px;
    background-size: 180px;
    background-repeat: no-repeat;
  }

  .navbar-collapse {
    margin: 0;
    padding: 0;
    border: none;

    .container-fluid {
      padding: 0;
    }
  }

  .navbar-default .navbar-collapse > .btn-group {
    padding-bottom: 10px;
  }

  .navbar .mode-switch {
    display: block;
  }

  .navbar .mode-switch > .btn-group {
    width: 100%;
  }

  .navbar .mode-switch > .btn-group > .btn {
    width: 25%;
    height: auto;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover,
    &.active:hover {
      background-color: darken($brand-primary, 10%);
    }

    &:active,
    &.active,
    .open > &.dropdown-toggle {
      background-color: $menu-background-inverse;
      box-shadow: none;
    }
  }

  .navbar .mode-switch .btn-group .btn + .btn {
    margin-left: 0;
  }

  .navbar-primary .btn-default {
    &:active,
    &.active,
    &.active:hover,
    .open > &.dropdown-toggle {
      background-color: $menu-background-inverse;
    }
  }

  .navbar-toggle {
    float: left;
  }

  .navbar-top-links {
    float: right;
  }
}

table a.fc-event {
  text-decoration: none;
  border: 0;
}

.fc-content .badge {
  background-color: #fff;
  color: #000;
  padding: 0px 4px;
  position: absolute;
  top: 2px;
  right: 0;
}

/* Selection Model Styles */
ul.fancy {
  padding: 0;
  list-style-type: none;
}

ul.fancy li {
  cursor: pointer;
  padding: 5px 10px;
}

ul.fancy li.selected {
  color: lavenderblush;
  background-color: #428BCA;
}

ul.err {
  padding: 0;
  list-style-type: none;
}

ul.err li {
  padding: 5px 10px;
}

.nav.nav-tabs {
  margin-bottom: 10px;
}

.label-warning {
  margin-right: 1em;
}

.clickable {
  cursor: pointer;
}

.padded-field {
  padding-left: 2em !important;
  padding-right: 2em !important;
}

.default-width {
  width: auto !important;
  display: inline-block !important;
}

.product-img {
  position: fixed;
  text-align: left;
}

.product-img img {
  height: 150px;
  border-style: solid;
  border-color: #ebebeb;
  border-width: 3px;
  margin-top: 10px;
  margin-left: 10px;
}

.a-disabled {
  color: #7A7A7A !important;
}

.text-bold {
  font-weight: bold;
}

.text-normal {
  font-weight: normal;
}

.text-gray {
  color: gray;
}

.td-title {
  background-color: #EEE;
}

.composition-name {
  margin-right: 10px;
  text-align: left;
}

.product-image {
  width:30px;
  height: 30px;
  display: inline-block;
  border-style: solid;
  border-width: 2px;
  border-color: #ebebeb;
  vertical-align: middle;
}

/* Catering pivot table */
.pivotTableContainer {
  overflow-x: scroll;
}

@media print {
  .pvtAxisContainer, .pvtVals {
    display: none;
  }

  /* use !important to override pvTable hierarchy */
  .pvtRendererArea td, th {
    border: 1px solid gray !important;
    padding: 2pt !important;
    font-size: 10pt !important;
  }

  .pvtRendererArea td {
    font-weight: bold !important;
  }

  .pvtRendererArea tr:nth-child(even) {
    background-color: white !important;
    -webkit-print-color-adjust: exact !important;
  }

  .pvtRendererArea tr:nth-child(odd) {
    background-color: rgb(235, 235, 235) !important;
    -webkit-print-color-adjust: exact;
  }

  .pvtRendererArea th {
    background-color: rgb(220, 220, 220) !important;
    -webkit-print-color-adjust: exact;
  }
}
.datepicker {
  min-width: 95vw;
}

.small-datepicker {
  min-width: 95vw;
}

@media(min-width: $screen-md) {
  .datepicker {
    min-width: 930px;
  }

  .small-datepicker {
    min-width: 600px;
  }
}

.date-label {
  width:100%;
}

.text-box {
  background-color: #FFF;
  padding: 4px;
}

.margin-box-bottom {
  margin-bottom: 10px;
}

.small-small {
  font-size: 0.5em;
}

#mainGraph {
  height: 600px;
}

.dropdown-expand-right {
    right: 0;
    left: auto;
}

.editor-panel .panel-body {
  padding: 0;
}

.editor-panel .cke.cke_chrome {
  margin: -1px;
}

.editor-panel .panel-margin {
  padding: 15px;
}

.td-radio label,
.td-radio input[type="radio"] + span,
.td-radio input[type="radio"] + span::before,
.td-checkbox label,
.td-checkbox input[type="checkbox"] + span,
.td-checkbox input[type="checkbox"] + span::before
{
    display: inline-block;
    vertical-align: middle;
}

.td-radio,
.td-checkbox {
  padding-left: 5px;
}

.td-radio span,
.td-checkbox span {
  font-weight: normal;
  position: relative;
  padding-left: 5px;
}

.td-radio input[type="radio"],
.td-checkbox input[type="checkbox"]{
  position: relative;
  padding-left: 5px;
  font-weight: normal;
}

.td-checkbox input[type="checkbox"]:disabled + span {
  opacity: 0.35;
}
.td-checkbox input[type="checkbox"]:disabled + span::before {
 background-color: #eeeeee;
 cursor: not-allowed;
}

.ui-select-fundations {
    max-width: 60%;
    min-width: 20%;
}

.td-checkbox input[type="checkbox"]:disabled + span {
  opacity: 0.35;
}

.td-checkbox input[type="checkbox"]:disabled + span::before {
 background-color: #eeeeee;
 cursor: not-allowed;
}

#articleList table tr td {
  vertical-align: middle !important;
}

.table-history {
  p {
    margin: 2px;
  }

  .toggle-arrow {
    cursor: pointer
  }

  a.cancel-link {
    padding: 6px;
  }

  th.description-header {
    padding-bottom: 6px;
  }

  div.description-div {
    padding: 0;
    margin: 0;
  }

  div.description-row-border {
    border-bottom-color: $gray-lighter;
    border-bottom-style: solid;
    border-bottom-width: 0.4px;
  }

  div.description-row {
    margin: 0;
  }

  div.description-row-padding-bottom {
    padding-bottom: 8px;
  }

  div.description-row-padding-top {
    padding-top: 8px;
  }

  div.description-row-transparent-border {
    // apply empty border on last description item to avoid unaligned cell border
    border-bottom-width: 0.4px;
    border-bottom-style: solid;
    border-bottom-color: transparent;
  }

  .canceled {
    text-decoration: line-through;
  }

  .menu-description {
    color: #9b9797;
    font-size: 11px;
  }
}

table.table.table-striped tr.tr-selected td {
    background-color: $brand-primary;
    color: #fff;
}

table.table.table-striped tr.tr-selected td a {
    color: #fff;
}

.inline {
  display: inline-block;
}

.inline.spaceleft {
  padding-left: 0.5em;
}

.btn-entrypoint-choice {
  background-color: white;
  width: 100%;
  margin-bottom: 1em;
}

.slide.ng-hide {
  right: 0;
}

.slide.ng-hide-add {
  transition: all linear 1s;
}

.btn-weez-inline {
  margin: 0 7px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.btn-weez-inline:hover {
  color: #333;
}

.preorder-config-period {
  background-color: #F7F3EB;
  padding-top: 5px;
  padding-bottom: 10px;
}

.preorder-config-day-label {
  padding-left: 5px;
  padding-right: 5px;
  margin: 0px;
}

.preorder-config-index {
  color: $brand-primary;
}

.preorder-config-period-column {
  padding: 0px 10px 0px 10px;
}

.event-block {
  border-style: solid;
  border-width: 1px;
  border-color: $table-border-color;
  transition: border-color 200ms linear;
  transition: color 200ms linear;
  margin-bottom: $line-height-computed;
  position: relative;
}

.event-block-container {
  max-width: $event-block-max-width;
  margin-bottom: $event-block-container-margin-bottom;
}

.event-closed {
  background-color: $gray-lighter;
}

.event-block:hover {
  border-color: $gray-light;
}

.event-block:hover .event-link {
  color: $event-block-link-color;
}

.event-block a:hover {
  text-decoration: none;
  color: $event-block-link-color;
}

.event-block .event-title span {
  font-style: italic;
  font-size: $font-size-h5;
  color: $gray-light;
  text-align: center;
  display: block;
}
.event-block .event-title .mainEventID {
  display: inline-flex;
  align-items: center;
  gap: 10px;
}
.event-block .event-title .mainEventID .textCopySuccess{
  display: none;
}
.event-block .event-title .mainEventID.copied .textCopySuccess{
  display: block;
}

.event-block .event-title h1 {
  font-size: $font-size-h3;
  color: $gray-light;
  text-align: center;
  margin-top: calc($line-height-computed / 2);
  margin-bottom: calc($line-height-computed / 2);
}

.event-block .event-shortcuts-bar {
  text-align: center;
  font-size: $font-size-h4;
  vertical-align: middle;
  margin-top: calc($line-height-computed / 2);
}

.event-block .event-status h2 {
  font-size: $font-size-h5;
  text-align: center;
  color: $gray-light;
}

.event-block .event-status p {
  font-size: $font-size-h6;
  text-align: center;
  color: $gray-light;
}

.event-block .event-status i {
  color: $gray-base;
  padding-right: $padding-small-horizontal;
  font-size: $font-size-base;
}

@media(min-width:768px) {
  .event-block {
    height: $event-block-height;
  }

  .event-block .event-title div {
    height: ($event-block-height - $line-height-computed);
  }

  .event-block .event-status {
    padding-left: 0px;
    margin-top: 0;
    height: $event-block-height;
  }

  .event-block .event-status h2 {
    text-align: right;
    margin: 0;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }

  .event-block .event-status p {
    text-align: right;
    margin: 0;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    white-space: nowrap;
  }

  .event-block .event-title h1, .event-block .event-title span {
    text-align: left;
  }

  .event-block .event-shortcuts-bar {
    text-align: center;
    visibility: hidden;
    margin-top: 0;
    height: $event-block-height;
    padding-left: 0px;
    padding-right: 0px;
  }

  .event-block .event-shortcuts-bar div {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
}

.event-block:hover .event-shortcuts-bar {
  visibility: visible;
}

.system-block {
   border-style: solid;
   border-width: 1px;
   border-color: $table-border-color;
   border-radius: 4px;
   transition: border-color 200ms linear;
   transition: color 200ms linear;
   margin-bottom: $line-height-computed;
   position: relative;
}

.system-block:hover {
  background: $brand-primary;
}

.system-block h4 {
  margin: 0;
}

.system-block p {
  color: $gray-light;
  margin: 0;
  padding: 0.5em;
  display: block;
  padding-top: calc($line-height-computed / 2);
  padding-bottom: calc($line-height-computed / 2);
  font-size: 16px;
}

.system-block h4 > a {
  padding: 0.5em;
  display: block;
  color: inherit;
  padding-top: calc($line-height-computed / 2);
  padding-bottom: calc($line-height-computed / 2);
  font-size: 24px;
}

.system-block a:hover {
  text-decoration: none;
  color: white;
  font-weight: 500;
}

.system-block:hover p {
  color: white;
}

@media(min-width:768px) {
  .system-block {
    margin-bottom: $line-height-computed;
    min-height: ($system-block-height + 40px);
  }

  .system-block h4 > a {
    min-height: $system-block-height;
  }
}

table.table-fixed {
  table-layout: fixed;
}

.dashboard-option-button {
    position: relative;
    display: inline-block;
    height: 2em;
    line-height: 2em;
    vertical-align: middle;
}

.dashboard-group-by {
  margin:5px;

  .ui-select-container {
    white-space: nowrap
  }
}

.dashboard-filter {
  text-align: center;
  margin-bottom:5px;

  .dropdown {
    display: block;
  }

  .dropbtn {
    cursor: pointer;
    height: 45px;
    width: 100%;
    white-space: normal;
    vertical-align:middle;
    color: $btn-default-color;
    background-color: $brand-primary;
    border-color: $brand-primary;
  }

  button:disabled, button[disabled] {
    border-color: #cccccc;
    background-color: #cccccc;
  }

  .dropdown-content {
    cursor: pointer;
    left: 0px;
    top: 100%;
    white-space: nowrap;
    padding-top: 2%;
    background-color: #f9f9f9;
    display: block;
    position: absolute;
    z-index: 1;
    margin: 0px;
    text-align: center;
    width: 100%
  }

  .dropdown:hover .dropbtn:enabled, .activedropbtn {
    background-color: $event-block-link-color;
    border-color: $event-block-link-color;
  }

  .btn {
    white-space: normal;
  }

  .restrict-choices-input {
    text-align: center;
    width: 95%;
  }

  .choices {
    text-align: left;
    padding-left: 5%;

    label {
      display: initial;
      margin-bottom: initial;
      font-weight: initial;
      cursor: pointer;
      text-decoration: none;
    }

    .choices-list {
      overflow: scroll;
      max-height: 300px;
    }
  }
}

.dashboard-graph {
  min-width: 310px;
  height: 500px;
}

.dashboard-table {
  overflow: auto;
  width: 100%;
  white-space: nowrap;

  @for $i from 0 through 10 {
    .dashboard-table-offset-#{$i} {
      padding-left: $i * $font-size-base;
    }
  }
}

.dashboard-config-button {
  background-color: $gray-light;
  border-color: $gray-light;
  height: 60px;
  width: 100%;
  color: $btn-inverse-color;
}

.dashboard-config-button-active, .dashboard-config-button:hover {
  background-color: $gray;
  border-color: $gray;
  color: $btn-inverse-color;
}

.row.dashboard-row {
  margin-bottom: 10px;
}

.saleskeyboard-btn {
  background-color: transparent;
  display: inline-block;
  border-radius: 0;
}

.saleskeyboard-btn-selected {
  outline: $brand-primary solid 5px;
  outline-offset: -5px;
}

.saleskeyboard-button {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  color: #000000;
}

ul.header-dropdown {
  padding-left: 0;
}

.walletMenu {
  overflow-x: auto;
  white-space: nowrap;
  margin-bottom : 2%;
}

.walletMenu a {
  margin-right: 2%;
}

.wallet-closed {
  background-color: white;
}

.customTooltip .tooltip-inner {
  min-width: 200px;
  max-width: 300px;
}

.top-buffer {
  margin-top:20px;
}

.progress-bar-battery-text  {
  font-size: 14px !important;
  line-height: 18px !important;
}

.progress-bar-battery-estimation {
  background-color: #ababab !important;
}

.progress-bar-height-battery {
  height : 20px !important;
}

/* Large */
.filter-dashboard {
  display: flex;
  flex-flow: row nowrap;
  /* This aligns items to the end line on main-axis */
}

/* Medium screens */
@media (min-width: $screen-md) {
.filter-dashboard {
    /* When on medium sized screens, we center it by evenly distributing empty space around items */
    justify-content: space-around;
  }
}

/* Small screens */
@media (max-width: $screen-sm) {
  .filter-dashboard {
    /* On small screens, we are no longer using row direction but column */
    flex-direction: column;
    margin-right: 10px !important;
  }
}

.devicetracking-table {
  overflow: auto;
  width: 100%;
}

.italic {
  font-style: italic;
}

.autocomplete-wallet-closed {
  color: $gray-light !important;
  font-style: italic;
}

.active > .autocomplete-wallet-closed {
  color: white !important;
  font-style: italic;
}

.label-primary a .glyphicon {
  color: $btn-primary-color;

  :hover,
  :focus,
  .focus {
    color: darken($brand-primary, 10%);
  }
}

.alert-page-header {
  margin-top: $line-height-computed; /* same as alert margin-bottom */
}

a.weezticket {
  color: $brand-primary-ticket;
}

.nav > li > a, .nav > li > a:hover, .nav > li.active > a, .nav > li.active > a:hover {
  color: $text-color;
}

a.text-link {
  color: $text-color;
}

.violet-text {
  color: #9715ed;
}

.blue-text {
  color: #1423f5;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.label-with-tooltip {
  display: inline;
}

.label-config-choice {
  font-weight: normal;
  margin-bottom: 0;
}
.extra-fee-inner{
  background-color: #f5f5f5;
  padding: 15px;
}
.extra-fee-inner .btn-default{
  background-color: white;
}


.inline-checkboxes {
  display: flex;
  gap: 20px;
  flex-wrap: wrap
}

.label-not-bold {
  font-weight: normal;
}


.indent-block {
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 15px;

  &--grey {
    background-color: $well-bg;
  }

  &--white {
    background-color: white;
  }
}

.purple-block {
  background-color: #E5CCFF;
  border: 1px solid #9933FF;
  padding: 14px 12px;
}

.purple-text {
  color: #9933FF;
}

.green-block {
  background-color: #CDF0CC;
  border: 1px solid #39C433;
  padding: 14px 12px;
}

.green-text {
  color: #39C433;
}

.red-block {
  background-color: #FEEFEF;
  border: 1px solid #F4080A;
  padding: 14px 12px;
}

.red-text {
  color: #F4080A;
}

.splitter {
  background-color: #ced0da;
  margin: 15px 0;
  height: 1px;
  border: none;
}

.search-location-container{
  display: inline-block;
  vertical-align: middle;
  width: 20%;
  position: relative;
  margin-left: 10px;
}
.search-location-container .fa-search{
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.filter-operator {
  font-size: 16px;
  font-weight: bold;
}

.select-with-ellipsis select {
  width: 100%;
  overflow:hidden;
  white-space:nowrap;
  text-overflow:ellipsis;
}

.closeout-modal__detail-item {
  display: flex !important;
  flex-direction: column;
  gap: 3px;
  align-items: center;
}

.visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.wz-date-picker .wz-popin__container {
  width: auto;
}

.ui.dropdown .menu > .item {
  font-size: 14px;
}

.ui.multiple.dropdown > .label {
  font-weight: normal;
}

/* Attendee page - Pinned comments */

.pinned-comments-panel-body {
  padding: 5px 0 10px 0;
}

.pinned-comments-table {
  width: 100%;

  tr {
    border-bottom: 1px solid #ddd;
  }

  td {
    padding-bottom: 8px;
    padding-top: 8px;
  }

  .last-row {
    border-bottom: none !important;
  }
}

.pinned-comments-subtitle {
  color: #999;
  font-size: 12px;
}

.pinned-comments-see-more {
  color: black;
  font-weight: 500;
  padding-left: 15px;
  text-decoration: underline;
  margin-bottom: 5px;
}

.thumbtack {
  cursor: pointer;
  color: black;
}

.thumbtack-icon {
  width: 10.5px;
  height: 14.67px;
  &:hover {
    background-color: darken($brand-primary, 15%);
  }
}
