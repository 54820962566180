.wz-daphne__recurring-timeslots {
  margin-top: 0.375rem;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}

.wz-daphne__recurring-timeslots__item {}

.wz-daphne__recurring-timeslots__item__item__inputs {
  flex-grow: 10;
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
}

.wz-daphne__recurring-timeslots__buttons {
  padding: 0.5rem;
}
