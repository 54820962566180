.wz-daphne__bank-bag-summary {
  &__informers {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
