.wz-daphne__bank-bag-settlements-empty-state {
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  &__title {
    font-size: 1.15em;
    font-weight: 500;
  }

  &__subtitle {
    font-size: 14px;
    color: #878C9B;
  }
}
