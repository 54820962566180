.bold {
    font-weight: 500;
}

.columnHeader {
    color: #111213;
}

.subLabel {
    font-size: 10px;
}
