.fieldWrapper {
  position: relative;
}

.copyArea {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  border: none;
  background-color: transparent;
}

.copyIcons {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  --primary-color: 255, 204, 51;
  cursor: pointer;
}
