.wz-c-form-field-multi-select {
  &__field--error {
    .ui.selection.dropdown.error {
      border-color: #F4080A !important;
    }

    .ui.selection.dropdown:focus, .ui.selection.dropdown:hover, .ui.selection.active.dropdown, .ui.selection.active.dropdown:hover, .ui.selection.active.dropdown .menu, .ui.selection.active.dropdown:hover .menu {
      border-color: #F4080A !important;
    }
  }

  &__error-message {
    color: #F4080A;
    padding-top: 10px;
    display: block;
  }
}
