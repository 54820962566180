.wz-c-price-cell {
  &__advanced-price {
    position: relative;
    margin-left: 8px;
    width: 17px;
    top: 2px;
  }
}

.wz-c-price-cell {
  &__variable-price-icon {
    display: inline-flex;
    position: relative;
    margin-left: 8px;
    top: 3px;
  }
}
