$size: 42px;
$grey: #8F96A3;

.root {
  :global(i.wz-font.wz-font-margin) {
    margin: 0 !important;
    position: relative;
    top: 1px;
  }
}

.circle {
  width: $size;
  height: $size;
  border-radius: 50%;
  margin-bottom: 8px;
  background-color: #EDEDED;

  .icon {
    color: $grey;
  }

  &[data-done=true] {
    background-color: #36B37E;

    &[data-red=true] {
        background-color: #EB1427;
    }

    .icon {
      color: white;
    }
  }
}

.name {
  margin-bottom: 12px;
  font-weight: 500;
}

.operator {
  margin-bottom: 4px;
  font-weight: 500;
}

.date {
  color: $grey;
}

.place {
  margin-bottom: 7px;
  color: $grey;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 500;
}
