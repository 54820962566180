.root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: background-color 0.22s ease-out;

  &:hover {
    background-color: #fff9e6;
  }

  &.isActive {
    background-color: #FFCC33;
  }
}

.title {
  text-transform: uppercase;
  color: #5E636E;
  font-size: 14px;
}

.quantity {
  font-weight: 500;
  font-size: 24px;
}
