.bold {
  font-weight: 500;
}

.action {
  display: flex;
  justify-content: flex-end;
}

.actionsDropdown {
  position: relative;
  right: 19px;
}
