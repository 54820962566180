.wz-c-variable-price {
  margin-bottom: -15px;

  .wz-toggle-container__label {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    margin-top: 15px;
  }

  .wz-toggle-container__title-container {
    margin-left: 5px;
  }
}

.wz-c-variable-price__positive-negative-price {
  .wz-field-block {
    margin: 0 0 10px;
  }

  &__label {
    display: block;
  }
}
