.images-library-tab-button {
  padding: 15px;
  position: relative;
  cursor: pointer;
  margin: 0;
  font-weight: 500;

  &[data-checked=true] {
    background: #fffef5;

    &::after {
      display: block;
      content: '';
      width: 100%;
      height: 3px;
      background-color: #ffcc33;
      position: absolute;
      left: 0;
      bottom: 0;
    }
  }

  &[data-disabled=true] {
    cursor: not-allowed;
    opacity: 0.5;
  }
}
