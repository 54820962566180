$red: var(--color-danger);
$green: #39C433;
$orange: #FAA43E;

@mixin addColor($color) {
  color: $color;

  .progressFill {
    background-color: $color;
  }

  .value {
    color: $color;
  }
}

.root {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 4px;

  .progress {
    width: 90px;
    background-color: #D9D9D9;
  }

  .value {
    font-weight: 500;
  }

  .progressFill, .progress {
    height: 8px;
    border-radius: 4px;
  }

  &[data-color="red"] {
    @include addColor($red);
  }

  &[data-color="green"] {
    @include addColor($green);
  }

  &[data-color="orange"] {
    @include addColor($orange);
  }
}
