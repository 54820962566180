.root {
  transition: background-color, color 0.25s ease;
  padding: 7px 11px;
  cursor: pointer;
  border-radius: 5px;

  &:hover, &:focus {
    outline: none;
    background-color: rgba(var(--medium-color), 0.13);
    color: rgb(var(--darker-color));
  }
}
