.currencyGroupSelect {
  flex-grow: 4;
}

.addCurrencyGroupButton {
  position: relative;
  top: 8px;
  height: 43px;
  flex-grow: 1;
}

.currencyGroupPart {
  margin-bottom: 20px;
}
