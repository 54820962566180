.wz-c-information-table {
  width: 100%;

  & > tr:not(:last-child) > td {
    padding-bottom: 16px;
  }

  & > tr {
    vertical-align: top;
  }
}

.wz-c-information-row {
  &__name {
    font-weight: 500;
    width: 40%;
  }

  &__value {
    color: var(--color-secondary-text);
  }
}
