@import "../../../../../../../../styles/mixins";

.fieldsTable {
  display: grid;
  grid-template-columns: 1fr 140px 140px 40px;

  @include tablet {
    grid-template-columns: 1fr;
    :global(.wz-currency-input) {
      max-width: initial !important
    }
  }

  gap: 10px;

  :global(.wz-field-block) {
    margin: 0;
  }
}

.mobileSplitter {
  display: none;
  height: 1px;
  background-color: #CED0DA;

  @include tablet {
    display: block;
  }
}

.removeButton {
  height: 40px;
  border-radius: 50%;
  display: flex;
  width: 40px;
  position: relative;
  top: 22px;

  .trash {
    position: relative;
    left: 2px;
  }

  .cta {
    display: none;
  }

  @include tablet {
    top: 0;
    border-radius: 6px;
    width: fit-content;
    display: flex;
    gap: 10px;

    .cta {
      display: inline;
    }

    .trash {
      left: 0;
    }
  }
}

.cta {
  font-weight: 500;
}
