.wz-daphne__period-config-block {
  display: flex;
  gap: 0.5rem;
}

.wz-daphne__period-config-block__item-index {
  color: #ffcc33;
  font-size: 1.5rem;
  font-family: Graphik;
  flex-grow: 1;
  max-width: 2rem;
}

.wz-daphne__period-config-block__item-content {
  width: 100%;
}

.wz-daphne__period-config-block__item-close {
  text-align: right;
  font-size: 1rem;
  flex-grow: 1;
  max-width: 2rem;
}
