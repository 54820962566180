.exchangeRateInfo, .equal {
  font-size: 16px;
  font-weight: 500;
}

.equal {
  position: relative;
  top: 7px;
}

.inputs {
  flex-basis: 250px;
  flex-grow: 1;
}

.currencyCode {
  flex-grow: 20;
}

.value {
  flex-grow: 1;
}
