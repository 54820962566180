.root {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  dt {
    color: #878C9B;
    font-weight: 400;
  }

  & > * {
    flex-grow: 1;
  }
}
