.row {
  background-color: #FFFFFF !important;
}
.mergedRow {
  background-color: #F8F9FC !important;
}

.bold {
  font-weight: 500;
}

.action {
  display: flex;
  justify-content: flex-end;
}
