.wz-c-online-settings {
  &__name-and-description {
    flex-grow: 1;
  }

  &__splitter {
    margin-top: 16px;
    margin-bottom: 16px;
    height: 1px;
    background-color: var(--color-border-grey);
  }

  .wz-optional-section__content {
    max-height: initial !important;

    &-close {
      max-height: 0 !important;
    }
  }

  &__image-dropzone {
    background-color: #EDEDED;
    border-color: #CED0DA;
  }

  &__preparation-time-wrapper {
    padding-top: 20px;
  }
}

.wz-c-online-settings-images-dropzone {
  font-size: 15px;
}

.wz-c-online-settings-images-display {
  .dropZoneStyle {
    width: 125px;
    height: 125px;
    min-height: 125px;
    align-self: center;
    border: 2px solid !important;
    border-color: lightgrey !important;

    &:hover {
      border-color: lightgrey !important;
      filter: grayscale(60%) blur(1px);
    }
  }
}

.wz-c-online-settings-images-display__thumbnail {
    .dropZoneStyle {
      width: 125px;
      height: 125px;
      min-height: 125px;
      align-self: center;
      border: 2px solid rgb(var(--primary-color)) !important;

      &:hover {
        border-color: rgb(var(--primary-color)) !important;
        filter: grayscale(60%) blur(1px);
      }
    }
  }

.wz-c-online-settings-images-display-icons {
  position: absolute;
  margin-top: 46px;
  margin-left: 33px;
  visibility: hidden;
}

.wz-c-online-settings-images-display:hover ~ .wz-c-online-settings-images-display-icons,
.wz-c-online-settings-images-display-icons:hover {
  visibility: visible;
}

.wz-c-online-settings-images-display__thumbnail:hover ~ .wz-c-online-settings-images-display-icons,
.wz-c-online-settings-images-display-icons:hover {
  visibility: visible;
}

