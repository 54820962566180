.reset-angular-style {
  .radio input[type=radio], .radio-inline input[type=radio], .checkbox input[type=checkbox], .checkbox-inline input[type=checkbox] {
    margin-left: 0;
    margin-top: 0;
    position: static;
  }

  input[type=radio], input[type=checkbox] {
    margin-top: 0;
  }

  .arrow {
    float: initial !important;
  }

  hr {
    display: block;
    margin: 0.5em auto;
    border-style: inset;
    border-width: 1px;
  }

  label {
    font-weight: normal;
  }

  .wz-popin {
    z-index: 1000000000 !important;
  }
}
