.inlined {
  display: inline-block;
}

.blocked {
  display: block;
}

.system-entities-import-modal {
  .counter {
    width: 110px;
  }
}
