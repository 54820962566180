.wz-p-refundable-counter-edit-page {
  &__counter-wrapper .wz-ui-input {
    width: 120px;
  }
}



.refundable-items-list {
  padding: 0;
  display: flex;
  gap: 3px;
  flex-wrap: wrap;
  flex-grow: 1;
}

.refundable-items-list-item {
  margin-bottom: 3px;
  list-style: none;
  border-radius: 11px;
  padding: 7px;
  background-color: #EDEDED;
  color: black;
  font-weight: normal;
  box-sizing: border-box;
  height: 30px;
  font-size: 12px;
  transition: box-shadow ease-in-out 0.15s;

  &:hover {
    border: 1px solid #CED0DA;
    padding: 7px 6px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  }

  &__link {
    display: flex;
    align-items: center;
    gap: 6px;
    color: black;

    &:hover {
      color: black !important;
      text-decoration: none !important;
    }

    &:visited {
      color: black !important;
      text-decoration: none !important;
    }

    i.wz-font:hover {
      color: black !important;
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

.wz-daphne__refundableCounterEditPage__customOption {
  display: flex;
  gap: 5%;
}

.wz-daphne__refundableCounterEditPage__customOption__deleteButton {
  font-size: large;
  align-self: center;
  justify-content: end;
}

.wz-daphne__refundableCounterEditPage__customOption__offsetCounter {
  flex-basis: 40%;
}

.wz-daphne__refundableCounterEditPage__customOption__offsetCounter .wz-ui-input {
  width: 120px;
}

.wz-daphne__refundableCounterEditPage__customOption__walletGroup {
  flex-basis: 50%;
  align-items: flex-start;
}

.wz-daphne__refundableCounterEditPage__customOption__walletGroup .selection {
  height: 43px;
}

.wz-daphne__refundableCounterEditPage__customOption__offsetCounterDisplay {
  display: flex;
  flex-flow: column wrap;
}

.wz-daphne__refundableCounterEditPage__customOption__offsetCounterDisplay__offsetCounterDisplay {
  flex-basis : 60%;
}

.wz-daphne__refundableCounterEditPage__customOption__offsetCounterDisplay__errorOffset {
  flex-basis : 40%;
  padding-bottom: 2%;
}
