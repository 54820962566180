.wz-paginator-weezpay {
  display: flex !important;
  justify-content: center;
  margin: 10px 3px !important;
}

.wz-paginator .wz-paginator-button:not(.active):not(.disabled):hover {
		color: rgb(var(--primary-color)) !important;
}

.wz-paginator .active {
    background-color: rgb(var(--primary-color)) !important;
}

#page-wrapper {
  padding-bottom: 10px !important;
}

.wz-p-lost-payments-page-list {
  i.wz-font:hover {
    color: rgb(var(--primary-color)) !important;
  }
}
