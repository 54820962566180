.wz-c-category-rows-wrapper {
  &__row {
    background-color: #DEE3ED !important;
  }

  &__name-cell {
    font-weight: 700;
    display: flex;
    align-items: center;
    gap: 6px;

    &--empty {
      opacity: 0.5;
    }
  }

  &__label-cell {
    background-color: white !important;
    color: #0A121F !important;
    font-weight: 500 !important;
    border-color: #0A121F !important;
    border-radius: 5px !important;
    text-transform: none !important;
    margin-left: 5px !important;
  }
}
