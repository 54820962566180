.wz-daphne__period-config-block-row {
  flex-grow: 10;
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem
}

.wz-daphne__period-config-block-row__item {
  flex-grow: 1;
  flex-basis: 10rem;
}
