@import "src/styles/mixins";

$border-radius: 5px;
$height: 40px;
$horizontalPaddings: 4px;
$verticalPaddings: 4px;
$animation: cubic-bezier(.16,1.1,.51,.98);
$transition-duration: 0.27s;

.wz-c-segmented-control {
  background-color: #EDEDED;
  height: $height;
  width: fit-content;
  border-radius: $border-radius;
  padding: $verticalPaddings $horizontalPaddings;
  position: relative;
  overflow-x: auto;

  @include mobile {
    width: 100%;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 15px;
    margin: 0;
    border-radius: $border-radius;
    cursor: pointer;
    font-weight: 500 !important;
    z-index: 3;
    flex-grow: 1;
    position: relative;
    flex-shrink: 0;

    &[data-disabled="true"] {
      cursor: not-allowed;

      &::after {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
      }
    }
  }

  &__item-content {
    &[data-disabled="true"] {
      opacity: 0.5;
    }
  }

  &__movable {
    position: absolute;
    height: calc(100% - #{$verticalPaddings * 2});
    top: $verticalPaddings;
    left: 0;
    border-radius: $border-radius;
    transition: transform $animation $transition-duration, width $animation $transition-duration;

    &--active {
      z-index: 2;
      background-color: #FFCC33;
    }

    &--hover {
      z-index: 1;
      background-color: white;
    }
  }

  &[data-error=true] {
    border: 1px solid #f4080a;
  }
}
