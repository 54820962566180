.wz-c-linked-entities__render-buttons {
  margin-top: 10px;
  margin-bottom: 15px;

  .wz-button:last-child {
    padding: 12px 1px;
    background-color: transparent;

    &:hover {
      color: rgb(var(--primary-color));
    }
  }
}

.wz-c-linked-entities__smart-table-render {
  &:last-child{
    text-align: end;
  }
}

.wz-c-linked-entities__smart-table-min-width {
  min-width: 250px;
}


