.wz-daphne__form-field-edit-modal__subtitle {
  .wz-message {
    margin-top: 0;
    margin-bottom: 16px;

    .wz-tooltip {
      margin: 0;
    }
  }
}
