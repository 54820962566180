.wz-daphne__payment-methods__search-container {
  padding: 1rem;
}

.wz-daphne__payment-methods__smallText {
  color: #8F96A3;
}

.wz-daphne__payment-methods__detail_iconsList {
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
    margin-bottom: 15px;
}
.wz-daphne__payment-methods__detail_labelWithMargin {
    display: block;
    margin-bottom: 5px;
}
.wz-daphne__payment-methods__detail_typesSection {
  & input[type="radio"] {
    margin: 0;
  }
  & .wz-ui-input__errors:not(.wz-ui-input__disabled) label {
    border-color: #f4080a !important;
  }
  & .wz-ui-input__errors.wz-ui-input__disabled input {
    border-color: #ced0da !important;
  }
}

.wz-daphne__payment-methods__detail_missingType {
  color: #f4080a;
}
