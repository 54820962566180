.wz-daphne__bank-bags-page-edit {
  &__control-buttons {
    display: flex;
    align-items: center;
  }

  &__form-blocks {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}
