.wz-c-transaction-row {
  padding: 16px 8px;

  &--partially-cancel {
    background-color: #FFF7E3;
  }

  &--totally-cancel {
    background-color: #FEEFEF;
  }

  &__table {
    flex-grow: 1;
  }

  &-table {
    width: 100%;

    &__row {
      &[data-cancelled=true] * {
        text-decoration: line-through;
      };
      &[menu-row=true] * {
        color: var(--color-secondary-text);
      };
    }

    &__menu_row {
      color: var(--color-secondary-text);
      &[data-cancelled=true] * {
        text-decoration: line-through;
      };
    }

    th {
      color: var(--color-secondary-text);
      font-size: 12px;
      font-weight: 400;
    }

    tr > th:not(:last-child)  {
      padding-right: 8px;
    }

    td {
      padding-bottom: 6px;
    }
    
    td:first-child {
      width: 48px;
    }

    td:nth-child(2) {
      width: 40%;
    }

    td:nth-child(3),
    td:nth-child(4),
    td:last-child {
      width: 20%;
    }

    &__total-price-cell {
      font-weight: 500;
    }

    &__article-link {
      color: black !important;
    }
  }
}
