/** ---------- Global css utils classes ---------- **/
.wz-g-background-color-white {
  background-color: white;
}

.wz-g-display-block {
  display: block;
}

.wz-g-display-inline-block {
  display: inline-block;
}

.wz-g-display-inline {
  display: block;
}

.wz-g-display-flex {
  display: flex;
}

.wz-g-button-link {
  background: none;
  border: none;
  padding: 0;
  text-decoration: underline;
  cursor: pointer;
}

.wz-g-p0 {
  padding: 0;
}

.wz-g-m0 {
  margin: 0;
}

.wz-g-remove-nacre-field-block-margin {
  margin: -0.5em 0 -1em 0;
}

.wz-g-medium-bold {
  font-weight: 500;
}

.wz-g-semi-bold {
  font-weight: 600;
}

.wz-g-bold {
  font-weight: 700;
}


/** ---------- TO REFACTOR ---------- **/

.form-editor-selector {
  display: flex;
  flex-shrink: 0;
}

.form-editor-selector > * {
  margin-right: 10px;
}

.form-editor-selector__select {
  width: 50%;
}

@media screen and (max-width: 768px) {
  .form-editor-selector {
    flex-wrap: wrap;
  }
  .form-editor-selector__select {
    width: 100%;
    margin-bottom: 10px;
  }
}

.card {
  margin-bottom: 15px;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
}

/* Override the absurd Nacre classes */
.wz-card {
  margin-bottom: 0 !important;
}

.wz-container-modal {
  z-index: 1000 !important;
}

.react-toast-notifications__container {
  z-index: 1001 !important;
}

.wz-total-filters {
  color: black !important;
}

/** ---------- Common css classes related to daphne ---------- **/

.wz-daphne__common__nowrap-overflow-hide-scrollbar {
  overflow-y: auto;
  white-space: nowrap;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;
}

.wz-daphne__common__nowrap-overflow-hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.wz-daphne__common__list-table-header-object-count__label {
  font-size: 20px;
  font-size: 1.25rem;
}

.wz-daphne__common__list-table-header-object-count {
  background-color: #fff;
  display: flex;
  padding: 16px;
  padding: 1rem;
  flex-direction: row-reverse;
}

.wz-daphne__common__table-actions {
  white-space: nowrap;
}

.wz-daphne__common__table-actions__icon {
  display: inline-block;
  margin-right: 4px;
  margin-right: 0.250rem;
}

.wz-daphne__common__table-actions__icon:hover {
  color: #ffcc33;
}

.wz-daphne__scenario-trigger-top-up__enabled-toggle .wz-input-label {
    margin-left: 0;
}

i.wz-font.wz-font-click:hover {
  color: rgb(var(--primary-color)) !important;
}

.wz-button-primary {
  color: black !important;
}
