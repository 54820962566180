.analysis {

  .red {
    color: red;
  }

  .green {
    color: green;
  }

  .details {
    color: rgb(var(--primary-color));
    font-size: 12px;
    cursor: pointer;
    margin: 10px;
    border: none;
    background: none;
    font-weight: bold;
  }
}
