.cards {
  margin-bottom: 10px;
  overflow-X: auto;
  padding-bottom: 10px;

  & > * {
    flex-grow: 1;
    flex-basis: 360px;
  }
}

.actionRibon {
  padding-top: 15px;

  .order {
    align-self: flex-end;
  }
}