.wz-c-smart-table-min-width {
	min-width: 800px;
	& thead th {
		box-sizing: content-box;
	}
	& thead th:first-child {
		padding-left: 10px;
	}
}

.wz-c-smart-table-pagination {
	padding-top: 10px;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.wz-c-smart-table-loader-wrapper {
	position: relative;
	width: 100%;

	td > .wz-loadercard {
		position: static;
	}
}

.wz-c-smart-table-root {
	background-color: white;
}

.wz-c-smart-table__empty-wrapper {
	display: flex;
	justify-content: center;
}

.wz-paginator__entities_table {
  position: fixed;
  bottom: 82px;
}
