$grey: #5E636E;

.option {
  border: 1px solid var(--color-border-grey);
  border-radius: 5px;
  padding: 12px;
}

.productName {
  font-weight: 500;
}

.productDescription {
  color: $grey;
}

.productInfo {
  flex-grow: 1;
}

.counterWrapper {
  max-width: 120px;
}

.totalQuantity {
  padding-top: 12px;
}

.totalQuantityLabel {
  font-size: 16px;
  color: $grey;
}

.totalQuantityValue {
  font-size: 24px;
  font-weight: 600;
}
