.header {
  background-color: #F8F9FC !important;

  & > th {
    height: 48px;
    border-bottom: 1px solid var(--color-border-grey) !important;
    border-top: 1px solid var(--color-border-grey) !important;
    padding: 8px 16px !important;
  }
}
