.wz-c-items-count-label {
  font-size: 24px;
  padding: 19px 15px;
  display: flex;
  justify-content: flex-end;

  &__counter {
    font-weight: bold;
  }
}
