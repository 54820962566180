.content {
  padding: 0 !important;
}

.priceValue {
  font-weight: 500;
}

.totalName {
  font-size: 18px;
  font-weight: 500;
}

.totalValue {
  font-size: 18px;
  font-weight: 600;
}

.count {
  font-size: 16px;
}

.overview {
  padding: 15px 10px;
}

.firstOverviewRow {
  margin-bottom: 4px;
}

.secondOverviewRow {
  color: var(--color-secondary-text);
}

.tooltip {
  text-align: left;
}
