.wz-daphne__chip-data-mapping-page-list__table-actions {
  margin-bottom: 0.5rem;
  white-space: nowrap;
}

.wz-daphne__chip-data-mapping-page-list__details-block {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  display: flex;
  padding: 12px 12px 0px 12px;
  padding: 0.75rem 0.75rem 0rem 0.75rem;
  gap: 8px;
  gap: 0.5rem;
}

.wz-daphne__chip-data-mapping-page-list__table-rows--invalid {
  background-color: rgba(196, 51, 51, 0.15) !important;
}

.wz-daphne__chip-data-mapping-page-list__table-rows--in-use {
  background-color: rgba(57, 196, 51, 0.15) !important;
}

.wz-daphne__chip-data-mapping-page-list__table-rows--to-migrate,
.wz-daphne__chip-data-mapping-page-list__table-rows--to-generate {
  background-color: rgba(250, 164, 62, 0.15) !important;
}
