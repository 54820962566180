.wz-daphne__form-field-week-days-select {
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  gap: 1rem;
}

.wz-daphne__form-field-week-days-select__checkboxes {
  display: flex;
  gap: 0.5rem;
}

.wz-daphne__form-field-week-days-select__checkboxes__item {
  display: flex;
}
