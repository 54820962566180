.images-library-accordion {
  &__title {
    padding: 10px;
    background-color: #F1F3F9;
    border-radius: 4px;
    font-weight: 500;
  }

  &__chevron {
    transition: transform 0.3s ease;
    position: relative;
    top: 2px;
    &[data-open=true] {
      transform: rotate(180deg) translateY(3px) translateX(3px);
      transform-origin: center;
    }
  }
}
