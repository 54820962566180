.quantity {
  font-size: 16px;
}

.gap {
  font-size: 12px;
}

.hasGap {
  color: #FAA43E;

  .quantity {
    font-weight: 500;
  }
}
