.wz-c-page-section-card {
  border: 1px solid #ced0da;
}

.wz-c-page-section-card-header {
  padding: 0.625rem 0.75rem;
  border-bottom: 1px solid #ced0da;
  display: flex;
  justify-content: space-between;
  align-content: center;
}

.wz-c-page-section-card-header-title {
  font-family: Graphik;
  font-size: 0.875rem;
  flex-grow: 1;
  align-self: center;
}

.wz-c-page-section-card-header-icon {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.wz-c-page-section-card-content {
  padding: 0.625rem 0.75rem;
}

.wz-c-page-section-card-header-buttons {
  display: flex;
}
