.wz-c-advanced-price {
  .reset-margin-from-field-block {
    .wz-field-block {
      margin: 0;
    }
  }

  .empty-state {
    background-color: #EDEDED;
    border-radius: 10px;
  }

  .wz-button-primary.wz-button__inverted {
    background-color: rgb(var(--primary-color));
    color: initial;
  }

  .add-price-row-cta {
    align-self: flex-start;
  }

  .wz-table tr, .wz-tablerow {
    border: 1px solid #CED0DA;
  }

  .wz-table {
    border-bottom: 1px solid #CED0DA;
  }

  .wz-button {
    padding: 5px;
    margin-bottom: 10px;
  }

  .wz-button-basic {
    font-weight: 500;
  }

  .wz-optional-section__label {
    margin-bottom: 0;
  }

  .wz-message {
    margin-bottom: 15px;
  }

  .wz-framebutton.wz-framebutton-with-icon.wz-framebutton-with-only-icon-mobile {
    flex-direction: row-reverse;
    border-color: #0f0f14;
    .wz-framebutton__icon {
      margin-right: 5px;
    }
  }
}
