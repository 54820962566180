.content {
  padding: 0 !important;
}

.controlsWrapper {
  padding: 10px;
}

.loaderWrapper {
  padding: 30px;
}

.searchWrapper {
  width: 360px;
  max-width: 360px;
}

.checkbox {
  margin: 0;
}
