@import "src/styles/mixins";

.wz-c-image-dropper-and-editor {
  width: 100%;
  min-height: 70vh;

  @include mobile {
    height: 100%;
  }

  .dropZoneStyle {
    flex-grow: 1;
  }
}
