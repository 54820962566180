.wz-c-sales-locations-details-page {
  &__form-blocks {
    display: flex;
    gap: 20px;
    flex-direction: column;
  }

  &__title {
    display: flex;
    align-items: center;
    gap: 12px;
  }
}
