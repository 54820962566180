.wz-daphne__scenario-page__buttons {
  display: flex;
  gap: 12px;
  gap: 0.75rem;
  margin-top: 8px;
  margin-top: 0.5rem;
}

.wz-daphne__scenario-page__tips {
  margin-top: 0;
}
