.wz-daphne__formfieldicon__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  border-radius: 4px;
  border: 1px solid #2F3237;
  cursor: pointer;
}

.wz-daphne__formfieldicon__icon > i {
  position: absolute;
}

.wz-daphne__formfieldicon {
  position: relative;
}

.wz-daphne__formfieldicon .wz-ui-input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.wz-daphne__formfieldicon .wz-input.wz-input-checkbox:checked::after {
  opacity: 0;
}

.wz-daphne__formfieldicon .wz-ui-input input {
  width: 100%;
  height: 100%;
  margin: 0;
  border-radius: 4px;
  border-color: #2F3237;
}

.wz-daphne__formfieldicon .wz-input.wz-input-radio:checked::after {
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
  border-radius: 0;
}
