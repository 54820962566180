.card {
  background-color: #E5B628;
  color: #fff;
  padding: 5px 16px;
}

.title {
  font-size: 16px;
  font-weight: 500;
}

.learnMore {
  background-color: transparent;
  border: none;
  text-decoration: underline;
  display: block;
  font-weight: 500;
  padding: 0;
  margin-top: 8px;
}
