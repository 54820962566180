.gap {
  font-weight: 500;
}

.gapOrange {
  color: #FAA43E;
}

.gapRed {
  color: #F4080A;
}

.gapGreen {
  color: #39C433;
}

.disabled {
  text-decoration: line-through;
}
