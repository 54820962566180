.root {
  overflow: hidden;
  border: 1px solid var(--color-border-grey);
  border-radius: 4px;
}

.title {
  font-weight: 500;
}

.header {
  padding: 12px 15px;
  background-color: #F8F9FC;
}

.content {
  padding: 20px;
}
