@import "src/styles/mixins";

.images-library-modal {
  &.wz-modal {
    padding: 0 !important
  }

  &__content {
    padding: 10px 15px;
    display: flex;

    @include mobile {
      @supports (-webkit-touch-callout: none) {
        height: calc(100vh - 130px);
      }

      @supports not (-webkit-touch-callout: none) {
        height: calc(100vh - 60px);
      }
    }
  }

  .wz-content-modal {
    max-height: 100vh;
  }
}
