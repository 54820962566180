.separator {
  background-color: var(--color-border-grey);
  height: 1px;
  margin: 12px 0;
}

.block {
  padding: 8px 16px;
}

.title {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 12px;
  display: block;
}
