.text {
  margin-bottom: 16px;
}

.label {
  color: #878C9B;
}

.editCta {
  background-color: transparent;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 8px;
}
