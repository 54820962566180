@import "app/styles/variables";
@import "../variables";

.wz-c-button-dropdown-main-button {
  padding: 10px;
  font-weight: 500;
  background-color: $brand-primary;
  border-radius: 6px 0 0 6px;
  transition: background-color $transition-delay;
  cursor: pointer;
  outline-color: black;
  outline-width: 2px;

  &:hover {
    background-color: $background-hover;
  }

  &[data-border-radius-right=true] {
    border-radius: 6px;
  }
}
