.wz-daphne__multi-language-email-template-editor {
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;

    .right {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }
}
