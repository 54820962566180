.wz-daphne__top-up-location__editPage label {
  font-weight: 400;
}
.wz-daphne__top-up-location__typeLabel {
  display: block;
}
.wz-daphne__top-up-location__advancedOptions,
.wz-daphne__top-up-location__advancedOptions__card{
  margin-top: 20px;
}
.wz-daphne__top-up-location__advancedOptions .wz-optional-form__label {
  font-weight: 700;
}
.wz-daphne__top-up-location__advancedOptions__deskContainer {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 12px;
}
.wz-daphne__top-up-location__advancedOptions__deskContainer :first-child {
  flex: 1;
  margin: 0;
}
.wz-daphne__top-up-location__advancedOptions__noDesk {
  text-align: center;
}
.wz-daphne__top-up-location__advancedOptions__deskItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  position: relative;
}
.wz-daphne__top-up-location__advancedOptions__deskItem .wz-field-block {
  width: 100%;
}
.wz-daphne__top-up-location__advancedOptions__deskItem .wz-font-trash {
  position: absolute;
  right: 0;
  top: 15px;
}

.wz-daphne__top-up-location__advancedOptions .wz-optional-form__card:hover {
  color: rgb(255, 204, 51) !important;
}

.wz-daphne__top-up-location__timer-time-wrapper {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  align-items: center;
  .wz-field-block {
    width: 100px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
}

.wz-daphne__top-up-location__toggle-check-balance-wrapper {
  .wz-field-block {
    padding-top: 10px;
  }
}

.wz-daphne__top-up-location__timer-warning {
  color: #5E636E;
  font-size: 14px;
}

.wz-daphne__top-up-location__timer-display-seconds {
  color: #5E636E;
  font-size: 12px;
}
