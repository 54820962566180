.wz-daphne__form-field-color-picker__modal {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;

  .twitter-picker {
    box-shadow: none !important;

    > div:nth-child(1), > div:nth-child(2) {
      display: none;
    }
  }
}
