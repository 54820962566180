.root {
  display: flex;
  align-items: center;

  & > div {
    padding: 14px 10px;

    &:nth-child(1) {
      flex-basis: 80px;
    }

    &:nth-child(2) {
      flex-basis: 60px;
    }

    &:nth-child(3) {
      flex-grow: 1;
    }

    &:nth-child(4), &:nth-child(5) {
      flex-basis: 120px;
    }
  }
}

.groupRow {
  background-color: #DEE3ED;
}
