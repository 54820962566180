#wz-daphne__chip-data-mapping-type-details {
  display: inline-block;
  font-size: 12px;
  font-size: 0.75rem;
}

.wz-daphne__chip-data-mapping-type-details__title {
  color: #878c9b;
  margin-bottom: 4px;
  margin-bottom: 0.25rem;
}

.wz-daphne__chip-data-mapping-type-details__details {
  display: flex;
  align-items: center;
  align-content: center;
}

.wz-daphne__chip-data-mapping-type-details__details__color {
  border-radius: 2px;
  border-radius: 0.125rem;
  width: 14px;
  width: 0.875rem;
  height: 14px;
  height: 0.875rem;
  margin-right: 4px;
  margin-right: 0.25rem;
}

.wz-daphne__chip-data-mapping-type-details__details__label {
  font-weight: bold;
  font-weight: 500;
}

.wz-daphne__chip-data-mapping__title__status .wz-dropdown-status__container {
  position: relative;
}
.wz-daphne__chip-data-mapping__title__status .wz-dropdown-status__container .wz-popin {
  position: absolute !important;
  inset: 25px auto auto 0px !important;
  transform: none !important;
}
