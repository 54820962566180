.root {
  --color: black;
  color: var(--color);
  display: inline-block;
  text-decoration: none !important;

  &[data-inherit-color=true] {
    --color: inherit;
  }

  &:hover {
    --color: var(--primary-color);
  }

  &:hover .text {
    text-decoration: underline !important;
  }

  .text {
    text-decoration: none !important;
    color: var(--color);
  }

  .icon {
    color: var(--color);

    &:hover {
      color: var(--color) !important;
    }
  }
}
