.wz-daphne__forms__frame-cards {
  gap: 16px;
  display: flex;
  flex-direction: column;
}

.wz-daphne__forms__frame-cards__item {
  display: flex;
  flex-direction: column;
}

.wz-daphne__forms__frame-cards__item__container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

#page-wrapper .wz-empty-card__container {
  margin-top: 16px;
  border-color: #ffcc33;
}