.root {
  height: 400px;
  background-color: #F8F9FC;
}

.title {
  font-size: 16px;
  font-weight: 500;
}

.subtitle {
  color: #878C9B;
}
