.wz-c-price-row-modal {
  label {
    font-weight: normal;
  }

  .empty-state {
    background-color: #ffffff;
    margin-top: 10px;
  }

  div.wz-dropdownButton-input {
    margin-right: 0 !important;
    padding-right: 50px;
  }
}
