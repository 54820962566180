.wz-c-product-choices {
  border: 1px solid var(--color-border-grey);
  border-radius: 6px;
  padding: 15px;

  &__title {
    font-size: 14px;
    font-weight: 600;
  }
}
