.wz-c-online-variants {
  &__name-translations-input {
    flex-grow: 1;
  }

  &__variant-image {
    position: relative;
    bottom: 5px;
  }

  &__quantity-restrictions {
    padding-top: 15px;
  }
}
