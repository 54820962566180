.wz-c-product-edit-price-block {
  &__available-toggle {
    .wz-field-block {
      margin: 15px 0 15px;
    }
  }

  .title {
    font-weight: 500;
  }

  .wz-input-switch.wz-input-switch__mini {
    margin-right: 5px;
  }

  .wz-field-block__right_inline label {
    margin-left: 0px;
  }

  .wz-field-block__right_inline {
    .wz-input-label {
      margin-left: 5px;
    }
  }
}

.wz-c-product-edit-price-purchase-block {
  margin-top: 30px;
  .wz-toggle-container__label {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
    margin-top: 15px;
  }

  .wz-toggle-container__title-container {
    margin-left: 5px;
  }
}
