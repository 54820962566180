.wz-daphne__top-up-location input:focus,
.paymentMethodsModal input:focus,
.importLocationsModal input:focus {
  outline: none !important;
}

.paymentMethodsModal input[type=checkbox],
.importLocationsModal input[type=checkbox] {
  margin-top: 1px;
}

.wz-daphne__top-up-location__headerTitle {
  display: flex;
  align-items: center;
  gap: 10px;
}
