.wz-c-optional-quantity {
  display: inline-block;

  &__quota-taken {
    font-weight: 500;
    position: relative;
    top: 7px;
  }
  &__optional-label {
    color: #878C9B;
    font-size: 12px;
  }
}
