.row {
  background-color: #DEE3ED !important;
}

.subrow {
  background-color: #C2C8D6 !important;
}

.nameCell {
  font-weight: 700;
  display: flex;
  align-items: center;
  gap: 6px;
}

.actionsCell {
  display: flex;
  justify-content: flex-end;
}
