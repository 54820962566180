.cta {
  font-weight: 500;
  cursor: pointer;
  background-color: transparent;
  display: flex;
  gap: 6px;
  align-items: center;
  outline: none;

  &:hover, &:focus {
    .ctaText {
      text-decoration: underline;
    }
  }
}

.splitter {
  border-top: 1px solid #CED0DA;
  margin-bottom: 16px;
}

.comment {
  color: #5E636E;
}
