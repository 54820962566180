.wz-c-sales-locations-table {
  &__actions-column {
    display: flex;
    justify-content: flex-end;
  }

  &__table-controls-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__table {
    td .wz-tooltip {
      margin: 0;
    }
  }
}
