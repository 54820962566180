.wz-c-product-edit-stock-block {
  .wz-headercard {
    display: flex;
    gap: 5px;
  }

  &__packaging-card {
    display: flex;
    align-items: center;
  }

  .wz-c-form-field-segmented-control {
    margin-bottom: 15px;
  }

  .wz-toggle-container__label {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    margin-left: 5px;
    margin-bottom: 3px;
    color: #0f0f14;
  }

  .wz-toggle-container__title-container {
    margin-left: 5px;
  }

  &__inputGroup-packagings {
    margin: 0.5em 0.5em 0.5em;
    .wz-field-block {
      margin: 0.5em 0 0.5em;
    }
  }

  &__inputGroup-compositions {
    margin: 0.5em 0.5em 0.5em;
    .wz-field-block {
      margin: 0.5em 0 0.5em;
    }
    .wz-input-group {
      width: 200px;
      justify-self: center;
    }
  }

  &__select {
    width: auto;
    display: flex;
    align-items: center;
    margin: 0.5em 0.5em 0.5em;
    .wz-field-block {
      margin: 0.5em 0.5em 0.5em 0;
      width: 350px;
    }
  }

  .wz-button {
    padding: 10px 10px 1px 1px;
  }

  .wz-button-basic {
    font-weight: 500;
  }

  .wz-framebutton.wz-framebutton-with-icon.wz-framebutton-with-only-icon-mobile {
    flex-direction: row-reverse;
    border-color: #0f0f14;
    .wz-framebutton__icon {
      margin-right: 5px;
    }
  }

  @media (max-width: 768px) {
    &__packaging-card {
      flex-direction: column;
      align-items: start;
    }

    &__select {
      flex-direction: column;
      align-items: start;
      .wz-field-block {
        width: 150%;
      }
    }
  }

  /* LinkedProducts Table */
  .wz-c-smart-table-root {
    margin-top: 8px;
  }

  .wz-c-smart-table-min-width {
    min-width: 250px;
  }

  .wz-table thead .wz-tablerow {
    background-color: #F8F9FC;
  }

  .wz-table thead .wz-tablecell, .wz-table thead th {
      font-size: 13px;
      font-weight: 500;
      color: #111213;
  }

  /* LinkedProducts Table */
}

.wz-c-product-form-field-composition,
.wz-c-product-form-field-composition__errors {
  .wz-card {
    margin: 10px 0;
    padding: 0 10px;
    border-radius: 5px;
    border-color: #ced0da;
  }
  @media (max-width: 768px) {
    .wz-card {
      flex-direction: column;
      align-items: start;
      padding: 10px;
    }
  }
}

.wz-c-product-form-field-composition__errors .wz-card {
  border-color: red;
}

.wz-c-product-edit-stock-wrapper {
  display: flex;
  justify-content: space-between;
}

.wz-c-product-edit-stock-trash-icon {
  display: flex;
  justify-content: space-between;
  align-items: center;
  i.wz-font.wz-font-regular {
    font-size: 1.3em;
  }
}

.wz-c-product-edit-stock-add-packaging-button {
  padding: 0;
}
