@import "src/styles/mixins";

.wz-c-product-edit-information-block {
  .wz-field-block {
    margin: 0;
  }

  .wz-tooltip-without-margin {
      display: inline;
    }

  .wz-popin__container {
      display: inline;
    }

  .wz-c-translatable-input__header {
    margin: 0;
  }

  .wz-input.wz-input-textarea {
    min-height: 50px;
  }

  &__category-create-button {
    height: 43px;
    border-color: black !important;
    color: black !important;
    position: relative;
    top: 22px;
  }

  &__category-input {
    flex-grow: 1;
  }

  &__top-field-list {
    flex-grow: 1;
  }

  .wz-input-multi {
    margin-bottom: 0;
  }

  &__image-edit {
    @include mobile {
      align-self: center;
      width: 100%;
    }
  }
}
