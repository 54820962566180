.removeMargin {
  margin: 0 !important;
}

.table {
  margin-bottom: 20px;
}

.errorMessage {
  position: absolute;
}

.cell {
  padding-bottom: 22px !important;
}
