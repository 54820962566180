.wz-c-smart-table-data-row-checkbox {
	width: 10px;
}

.wz-c-smart-table-table-data-row-data--clickable {
	&--clickable {
		cursor: pointer;
	}
}
.wz-c-smart-table-table-data-row-data:first-child {
	padding-left: 15px;
}
