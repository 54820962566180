$background-color-aborted: #FEEFEF;
$background-color-validated: #CDF0CC;
$background-color-waiting: #FEEDD8;

$primary-color-aborted: #F4080A;
$primary-color-validated: #39C433;
$primary-color-waiting: #FAA43E;

.root {
  width: 18px;
  height: 18px;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.aborted {
  fill: $primary-color-aborted;
  background-color: $background-color-aborted;
  border: 1px solid $primary-color-aborted;
}

.validated {
  fill: $primary-color-validated;
  background-color: $background-color-validated;
  border: 1px solid $primary-color-validated;
}

.waiting {
  fill: $primary-color-waiting;
  background-color: $background-color-waiting;
  border: 1px solid $primary-color-waiting;
}
