.root {
  border: 1px solid var(--color-border-grey);
  padding: 0 16px;
  height: 48px;
  border-radius: 6px;
}

.type {
  padding-left: 8px;
  margin-right: 12px;
}

.method_tooltip {
	color: black;

	text-transform: none;
	letter-spacing: normal;
	font-weight: normal;
	font-size: 10px;

	margin-right: 0;
}