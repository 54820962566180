@import "src/styles/mixins";

$image-size: 125px;

.wz-c-information-block-product-image {
  width: 250px;

  @include mobile {
    width: 100%;
    max-height: initial;
  }

  &__controls > * {
    flex-grow: 1;
  }

  &__dropper {
    background-color: #EDEDED;
    border: 1px dashed #CED0DA;
    height: 185px;
    border-radius: 5px;
  }

  &__image {
    max-width: 100%;
    max-height: 100%;
  }

  &__dropzone {
    width: $image-size;
    height: $image-size;
    min-height: $image-size;
    align-self: center;
  }
}
