.root {
  font-size: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  border-radius: 50%;

  &[data-checked=true] {
    background-color: #36B37E;
  }

  &[data-checked=false] {
    background-color: #8F96A3;
  }
}

.icon {
  color: white;
  position: relative;
  top: 1px;
  left: 1px;
}
