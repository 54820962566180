.wz-daphne__email-template-edit-page {
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .wz-right-toolsheader-components-no-dropdown {
    display: flex;
  }
}
