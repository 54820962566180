.wz-c-transaction-receipt-block {
  &__total {
    font-weight: 500;
    font-size: 18px;
  }

  &__date {
    color: var(--color-secondary-text);
  }

  &__splitter {
    margin: 12px 0 16px 0;
  }

  &__control-buttons {
    transition: background-color 0.3s ease-out;

    & > * {
      flex-grow: 1;
    }
  }

  &__cancel-selected-btn {
    --button-color: black;

    background-color: transparent !important;
    border-style: solid !important;
    border-width: 1px !important;
    border-color: var(--button-color) !important;
    color: var(--button-color) !important;
    overflow: hidden;

    &[disabled] {
      --button-color: #C2C9D6;
    }
  }

  &__cancel-all-transactions-btn {
    background-color: var(--color-danger) !important;

    &:hover {
      background-color: #d5070a !important;

      &:active {
        background-color: #b70609 !important;
      }
    }
  }

  &__list {
    padding-top: 16px;
  }
}
