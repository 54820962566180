.wz-c-product-row {
  &__name-cell {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  .wz-tooltip {
    margin: 0 !important;
  }
}
