.wz-product-tags-page-edit-accordion-child {
  overflow: visible !important;  /* remove the !important once we can override accordion's "overflow" style easily */

  .wz-accordion__title {
    border-radius: 5px 5px 0 0;
  }

  .wz-accordion__text {
    width: 100%;
  }
}
