.wz-daphne__top-up-location__listCard {
  padding-bottom: 10px;
}
.searchContainer {
  padding: 1rem;
}
.searchContainer .wz-input-with-right-component {
  align-items: center;
  justify-content: space-between;
}
.searchContainer .wz-input-with-right-component .wz-search-input {
  width: 60%;
}
.searchContainer .wz-c-items-count-label {
  padding: 0;
}
.dropdownsContainer {
  display: flex;
  justify-content: space-between;
  padding: 0 1rem 1rem;
}
.dropdownsContainer .wz-dropdown-input {
  max-width: none;
}

.top-up-locations-table .checkbox input[type="checkbox"] {
  position: static;
  margin-left: 0;
}
.top-up-locations-table ul {
  padding: 0;
}
.top-up-locations-table li {
  list-style: none;
}

.wz-daphne__top-up-location__statusDropdown .wz-status > div > div {
  display: flex;
}
