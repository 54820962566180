.iconWrapper {
  border-radius: 8px;
  border: 1px solid var(--color-border-grey);
  width: 40px;
  height: 40px;
}

.icon {
  position: relative;
  left: 2px;
  top: 1px;
}

.root {
  margin-right: 16px;
}
