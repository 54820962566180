#wz-daphne__chip-data-mapping {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  background-color: #ffffff;
  padding: 12px;
  padding: 0.75rem;
  width: 100%;
}

.wz-daphne__chip-data-mapping__title,
.wz-daphne__chip-data-mapping__storage-details {
  display: flex;
  align-items: center;
  gap: 8px;
  gap: 0.5rem;
  font-size: 14px;
  font-size: 0.875rem;
  padding: 0px 0px 8px 0px;
  padding: 0rem 0rem 0.5rem 0rem;
}

.wz-daphne__chip-data-mapping__title__text,
.wz-daphne__chip-data-mapping__title__status {
  display: inline-block;
}

.wz-daphne__chip-data-mapping__progress-bar__container {
  background-color: #dddddd;
  border-radius: 4px;
  border-radius: 0.250rem;
  width: 100%;
  height: 16px;
  height: 1rem;
}

.wz-daphne__chip-data-mapping__progress-bar__container__slot {
  display: inline-block;
  height: 16px;
  height: 1rem;
  border-radius: 2px;
  border-radius: 0.1250rem;
  border-right-width: 1px;
  border-right-width: 0.0625rem;
  border-right-style: solid;
  border-right-color: #dddddd;
}

.wz-daphne__chip-data-mapping__storage-details-per-type {
  margin-top: 12px;
  margin-top: 0.75rem;
  display: flex;
  gap: 16px;
  gap: 1rem;
}

.wz-commondropdown-list.wz-commondropdown-list__mini {
  min-width: 150px !important;
  width: auto !important;
}
