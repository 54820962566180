.wz-daphne__scenario-trigger,
.wz-daphne__scenario-trigger__content {
  display: flex;
  flex-direction: column;
  gap: 16px;
  gap: 1rem;
  background-color: white;
}

.wz-daphne__scenario-trigger__actions {
  display: flex;
  flex-direction: column;
  gap: 12px;
  gap: 0.75rem;
}

.wz-daphne__scenario-trigger__cta {
  display: flex;
  gap: 12px;
  gap: 0.75rem;
}

.wz-currency-input {
  display: flex;
  max-width: 10rem;
}

.wz-daphne__scenario-trigger__quota-counter .wz-ui-input {
  width: 120px;
}
