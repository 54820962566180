.wz-c-product-choice {
  &__product-select {
    flex-grow: 1;
  }

  &__remove-button {
    top: 4px;
    position: relative;
  }
}
