#wz-daphne__chip {
  background-color: #fffef5;
  display: flex;
  align-items: center;
  gap: 8px;
  gap: 0.5rem;
  padding: 4px 8px;
  padding: 0.25rem 0.5rem;
  border-radius: 16px;
  border-radius: 1rem;
  width: fit-content;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 0.125rem 0.25rem 0 rgba(0, 0, 0, 0.15);
}

.wz-daphne__chip__label {
  font-size: 12px;
  font-size: 0.75rem;
}
