.wz-daphne__form-field-period-configs {
  width: 100%;
  max-width: 900px;
  background-olor: #f9f9f9;
  padding: 0.5rem;
}

.wz-daphne__form-field-period-configs__buttons {
  display: flex;
  gap: 0.375rem;
  flex-wrap: wrap;
}

.wz-daphne__form-field-period-configs__buttons__item {
  display: inline-block;
  margin-bottom: 0.375rem;
}
