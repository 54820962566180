.wz-daphne__widget-interface-tab__content {
  display: flex;
  flex-direction: row;
  gap: 16px;
  gap: 1rem;
}

.wz-daphne__widget-interface-tab__content__left {
  display: flex;
  flex-direction: column;
  gap: 16px;
  gap: 1rem;
  width: 60%;
  max-width: 500px;
}

.wz-daphne__widget-interface-tab__content__right {
  display: flex;
  flex-grow: 1;
}

.wz-daphne__widget-interface-tab__content__right__content {
  width: 100%;
}

@media (max-width: 768px) {
  .wz-daphne__widget-interface-tab__content {
    flex-direction: column;
  }
  .wz-daphne__widget-interface-tab__content__left {
    width: 100%;
    max-width: none;
  }
}
