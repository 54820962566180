.refreshButton {
  & > * {
    color: black;
  }
}

.pageSizeSelectWrapper {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 15px;
}
