.dropdownTriggerWrapper {
  position: relative;
  width: 300px;
  height: 40px;
}

.dropdownTriggerInput {
  width: 100%;
  height: 100%;
  border: 1px solid #CED0DA;
  border-radius: 5px;
  padding: 12px;
}

.dropdownTriggerIcon {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
}

.groupName {
  font-weight: 500;
  padding: 7px 11px;
}

.triggerButton {
  padding: 0;
  background-color: transparent;
  outline: none;
}

.menuContent {
  min-width: 300px;
}
