.sublabel {
  color: var(--color-secondary-text);
  font-size: 12px;
}

.bold {
  font-weight: 500;
}

.separator {
  height: 16px;
}
