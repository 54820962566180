.subtitle {
  color: var(--color-secondary-text);
}

.splitter {
  margin: 16px 0;
  height: 1px;
  background-color: var(--color-border-grey);
}

.reset {
  $grey: #5E636E;
  color: $grey;


  :global(.i) {
    fill: $grey;
  }
}

.drawerContent {
  flex-grow: 1;
}

.drawerPanel {
  display: flex;
  flex-direction: column;
}
