$color-warning: #FAA43E;

.fieldWarning {
  input {
    border-color: $color-warning !important;
  }
}

.fieldWarningMessage {
  color: $color-warning !important;
}
