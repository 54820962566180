.wz-c-product-image {
  background-color: transparent;
  padding: 0;
  position: relative;

  &__image {
    border: none;
    background: transparent;
    width: 34px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    cursor: pointer;
  }

  &__overlay {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 6px;
    opacity: 0;
    transition: opacity 0.1s;

    &:hover {
      opacity: 1;
    }

    i {
      margin: 0 !important;
      color: white;

      &:hover {
        color: white !important;
      }
    }
  }
}
