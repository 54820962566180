// Css to correctly print rentals
@media print {
	.print {
		display: block !important;
	}

	.no-print {
		display: none;
	}

	ul.nav {
		display: none;
	}

	.small-table > thead > tr > th, .small-table > tbody > tr > th, .small-table > tfoot > tr > th, .small-table > thead > tr > td, .small-table > tbody > tr > td, .small-table > tfoot > tr > td {
		padding: 1px !important;
		font-size: x-small !important;
  }

	.closeout-table > thead > tr > td, .closeout-table > tbody > tr > td, .closeout-table > tfoot > tr > td {
		padding: 1px !important;
		font-size: 15px !important;
	}

	.especes-table > .form-inline > .form-group {
		margin: 0;
	}
}

@media screen {
	.screen {
		display: block;
	}

	.no-screen {
		display: none;
	}
}
