.wz-c-images-library-select {
  width: 100%;

  &__category-select {
    flex-grow: 3;
  }

  &__search {
    flex-grow: 7;
  }
}
