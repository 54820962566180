.wz-c-add-or-remove-items-modal {
  &__type-select {
    display: flex;
    gap: 8px;
  }

  &__type-select-item {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .wz-content-modal {
    overflow-y: initial !important;
  }
}
