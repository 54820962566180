.root {
  position: absolute;
  height: 2px;
  background-image: url("./LinePartGrey.svg");
  background-repeat: repeat-x;
  transform: translateY(-50%);

  &[data-connected=true] {
    background-image: url("./LinePartGreen.svg");
  }
}
