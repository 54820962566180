.drawer {
  --width: 600px;
}

.padding {
    padding-top: 16px;
}

.panel {
  max-width: 100vw;
  min-width: 320px;
}

.header {
  & > div {
    flex-grow: 1;
  }
}

.content {
  overflow-y: auto;
}
