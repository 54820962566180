.wz-daphne__period-configs {
  background-color: #f7f3eb;
  margin-bottom: 0.5rem;
  padding: 0.5rem;
}

.wz-daphne__period-configs__cards-datetime,
.wz-daphne__period-configs__cards-date {
  padding: 0.5rem;
}
