@import "app/styles/variables.scss";

$imageSize: 65px;

.wz-c-images-select {
  &__select-button {
    overflow: hidden;
    background-color: transparent;
    border: 1px solid #CED0DA;
    border-radius: 4px;
    box-sizing: border-box;
    width: $imageSize;
    height: $imageSize;
    padding: 0;
    position: relative;
    flex-shrink: 0;

    &[data-empty=true] {
      border: none;
    }

    &[data-selected=true] {
      border: 2px solid $brand-primary;

      &::after {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        content: '';
        background-color: rgba(#FFCC0033, 0.2);
      }
    }
  }

  &__image {
    display: block;
    max-width: 100%;
    max-height: 100%;
  }
}
