$width: 228px;

.translations-select {
  font-size: 14px;
  position: relative;
  display: inline-block;

  &__selected-item {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 6px;
    background: white;
    border: none;

    & > * {
      margin-right: 8px;
    }
    margin-right: -8px;
  }

  &__completed-counter {
    color: #878c9b;
  }

  &__dropdown {
    border-radius: 5px;
    box-shadow: 0 1px 7px 0 rgba(0, 0, 0, 0.09);
    border: solid 1px #ced0da;
    position: absolute;
    z-index: 9999999999;
    right: 28px;
    top: 28px;
    background-color: white;
    width: $width;
  }

  &__dropdown-item {
    padding: 12px 12px 12px 15px;
    cursor: pointer;
    transition: color .15s ease-out;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: transparent;
    border: none;
    width: 100%;

    &--selected, &:hover {
      color: #cc9e29;
    }
  }
}

.circled-check-mark {
  background-color: transparent;
  width: 17px;
  height: 17px;
  box-sizing: border-box;
  border: 1px solid #ced0da;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 10px;
  color: black;

  &--checked {
    background-color: #f2f2f2;
    border: none;
  }
}
